<template>
    <div>
        <DataTitle :title="$t('corporations.header.method')" />
        <div class="data-container">
            <div class="input-container">
                <FloatingDropdown :optionlist="confirmationoptionslist" :selected="corporation.confirmationprocess.toString()" :isloading="isloading" :title="$t('corporations.cofirmationmethod')" @change="handleProcessChange"/>
            </div>
        </div>

        <DataTitle :title="$t('corporations.header.approver')" v-if="corporation.confirmationprocess === 1"/>
        <div class="data-container" v-if="corporation.confirmationprocess === 1">
            <div class="input-container">
                <FloatingDropdown :optionlist="approveroptionlist" :selected="corporation.approver1.toString()" :defaultoption="true" :defaultoptionid="0" :isloading="isloading" :title="$t('corporations.primaryapprover')" @change="handlePrimaryApproverChange"/>
            </div>

            <div class="input-container">
                <FloatingDropdown :optionlist="approveroptionlist" :selected="corporation.approver2.toString()" :defaultoption="true" :defaultoptionid="0" :isloading="isloading" :title="$t('corporations.secondaryapprover')" @change="handleSecondaryApproverChange"/>
            </div>

            <div class="input-container">
                <FloatingDropdown :optionlist="approveroptionlist" :selected="corporation.approver3.toString()" :defaultoption="true" :defaultoptionid="0" :isloading="isloading" :title="$t('corporations.tertiaryapprover')" @change="handleTertiaryApproverChange"/>
            </div>
        </div>

        <DataTitle :title="$t('corporations.header.contacts')" v-if="corporation.confirmationprocess === 1"/>
        <div class="data-container" v-if="corporation.confirmationprocess === 1">
            <div class="input-container">
                <FloatingDropdown :optionlist="contactoptionlist" :selected="corporation.supervisor1.toString()" :defaultoption="true" :defaultoptionid="0" :isloading="isloading" :title="$t('corporations.primarycontact')" @change="handlePrimaryContactChange"/>
            </div>

            <div class="input-container">
                <FloatingDropdown :optionlist="contactoptionlist" :selected="corporation.supervisor2.toString()" :defaultoption="true" :defaultoptionid="0" :isloading="isloading" :title="$t('corporations.secondarycontact')" @change="handleSecondaryContactChange"/>
            </div>
        </div>

        <DataTitle :title="$t('corporations.header.settings')" v-if="corporation.confirmationprocess === 1"/>
        <div class="data-container" v-if="corporation.confirmationprocess === 1">
            <div class="input-container" v-if="!isloading">
                <div class="label-container">
                    <label>{{ $t('corporations.confirmation') }}</label>
                </div>
            </div>
            <div class="input-container custom" v-if="!isloading">
                <div v-for="variant in confirmationvariantlist" :key="variant.displayid" class="radio-container">
                    <input type="radio" :id="'variant-radio' + variant.displayid" :value="variant.displayid" :name="variant.displayname" v-model="corporation.confirmationtype">
                    <label class="radio-label" :for="'variant-radio' + variant.displayid" >{{ variant.displayname }}</label>
                </div>
                <div v-if="corporation.confirmationtype === 2" class="settings-container">
                    <FloatingDropdown :optionlist="deadlineoptionlist" :selected="corporation.confirmationtime.toString()" @change="handleTimeChange"/>
                    <FloatingDropdown :optionlist="intervaloptionlist" :selected="corporation.confirmationinterval.toString()" @change="handleIntervalChange"/>
                </div>
            </div>
            <div v-else class="input-container">
                <div class="input-line-loading-placeholder shimmer big"></div>
            </div>
            

            <div class="input-container add-space-top">
                <FloatingDropdown :title="$t('corporations.deadline')" :optionlist="deadlineoptionlist" :isloading="isloading" :selected="corporation.approvaldeadline.toString()" @change="handleDeadlineChange"/>
            </div>
        </div>

        <DataTitle :title="$t('corporations.header.app')" />
        <div class="data-container">
            <div class="input-container" v-if="!isloading">
                <a class="button" target="_blank" :href="corporation.approvalurl">{{ $t('corporations.applink') }}</a>
            </div>
            <div v-else class="input-container">
                <div class="input-line-loading-placeholder shimmer big"></div>
            </div>
        </div>

        <div class="button-container">
            <input :disabled="isloading" type="button" class="button" :value="$t('corporations.buttons.save')" @click="saveValues" />
        </div>
    </div>
</template>

<script>
import DataTitle from './systems/elements/DataTitle'
import FloatingDropdown from './systems/elements/FloatingDropdown'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    data() {
        return {
            isloading: false,
            confirmationoptionslist: [],
            confirmationvariantlist: [],
            approveroptionlist: [],
            contactoptionlist: [],
            deadlineoptionlist: [],
            intervaloptionlist: []
        }
    },
    computed: {
        corporation() {
            return this.$store.state.editcorporation
        }
    },
    mounted() {
        this.getConfirmationConfiguration()
    },
    methods: {
        getConfirmationConfiguration: function() {
            // TODO:
            this.isloading = true

            // GET '/clients/:clientid/confirmationprocess'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/confirmationprocess'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.confirmationoptionslist = data.confirmationtypes
                    this.confirmationvariantlist = data.confirmationvariants
                    this.deadlineoptionlist = data.deadlines
                    this.intervaloptionlist = data.intervals

                    for (var entry in data.contactlist) {
                        var contact = data.contactlist[entry]
                        var option = { displayid: contact.id, displayname: contact.firstname + ' ' + contact.lastname }

                        if (contact.roleid === 65) {
                            // approver
                            this.approveroptionlist.push(option)
                        } else if (contact.roleid === 64) {
                            // accounter
                            this.contactoptionlist.push(option)
                        }
                    }

                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
            
        },
        handleProcessChange: function(event) {
            this.corporation.confirmationprocess = event.displayid
        },
        handlePrimaryApproverChange: function(event) {
            this.corporation.approver1 = event.displayid
        },
        handleSecondaryApproverChange: function(event) {
            this.corporation.approver2 = event.displayid
        },
        handleTertiaryApproverChange: function(event) {
            this.corporation.approver3 = event.displayid
        },
        handlePrimaryContactChange: function(event) {
            this.corporation.supervisor1 = event.displayid
        },
        handleSecondaryContactChange: function(event) {
            this.corporation.supervisor2 = event.displayid
        },
        handleTimeChange: function(event) {
            this.corporation.confirmationtime = event.displayid
        },
        handleIntervalChange: function(event) {
            this.corporation.confirmationinterval = event.displayid
        },
        handleDeadlineChange: function(event) {
            this.corporation.approvaldeadline = event.displayid
        },
        saveValues: function() {
            this.isloading = true

            var sessionData = Vue.getSessionData()
            
            // PUT /clients/:clientid/corporations/:corporationid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/corporations/' + this.corporation.id

            var body = {
                corporation: this.corporation
            }

            axios.put(url, body)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with the saved corpo
                    //       so we have to push the current corporation back into the editCorporation
                    // this.$store.commit('newEditCorporation', this.corporation)

                    // the service has a very weird behavior when it comes to the approvers
                    // and supervisors, so for now we are better of reloading sending the user back
                    // to the overview...
                    this.$router.push({ name: 'corporations' })

                    this.isloading = false
                }
            })
            .catch(function(err) {
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'corporations'})
            })
        }
    },
    components: {
        DataTitle,
        FloatingDropdown
    }
}
</script>

<style scoped>
.data-container {
    margin-top: 30px;
}

.label-container {
    width: 100%;
    text-align: left;
    height: 25px;
}

.radio-container {
    margin-right: 20px;
    min-width: fit-content;
}

.input-container.custom {
    margin-top: 0px;
}

.input-container.custom .settings-container {
    display: flex;
    width: 100%;
}

.input-container.custom .settings-container :nth-child(1) {
    margin-right: 5px;
    flex: 2;
    min-width: 40px;
}

.input-container.custom .settings-container > .component-container:nth-child(1) >>> select {
    padding-left: 3px;
}

.input-container.custom .settings-container :nth-child(2) {
    flex: 8;
}
</style>