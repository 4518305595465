<template>
    <div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToCorpo()">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('corporations.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.corpoHeader }}</label>
                </div>
            </div>
            <!-- TODO: ?
                https://codepen.io/mildrenben/pen/bdGdOb
            -->
            <div class="page-nav-container">
                <div class="tab-nav-container">
                    <div class="tab-nav-button first" @click="goToEditCorporation()" :class="{ 'highlight' : this.currenttab === 0 }">
                        <div>{{ $t('corporations.corporation') }}</div>
                    </div>
                    <div class="tab-nav-button" @click="goToProductConfiguration()" :class="{ 'highlight' : this.currenttab === 1 }" >
                        <div>{{ $t('corporations.products') }}</div>
                    </div>
                    <div class="tab-nav-button" @click="goToConfirmationProcess()" :class="{ 'highlight' : this.currenttab === 2 }" >
                        <div>{{ $t('corporations.approvalmethod') }}</div>
                    </div>
                    <div class="tab-nav-button last" @click="goToFieldConfig()" :class="{ 'highlight' : this.currenttab === 3 }" >
                        <div>{{ $t('corporations.fields') }}</div>
                    </div>
                </div>
            </div>
            <div class="page-content-container">
                <EditCorporation v-if="this.currenttab === 0" />
                <ProductConfiguration v-if="this.currenttab === 1" />
                <ConfirmationProcess v-if="this.currenttab === 2" />
                <FieldConfig v-if="this.currenttab === 3" />
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/caret-left'

import EditCorporation from '../components/EditCorporation.vue'
import ProductConfiguration from '../components/ProductConfiguration.vue'
import ConfirmationProcess from '../components/ConfirmationProcess.vue'
import FieldConfig from '../components/FieldConfig.vue'

export default {
    name: 'EditCorporationPage',
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.editcorporation === null) {
                next('/corporations')
            } else {
                next()
            }
        })
    },
    data() {
        return {
            currenttab: 0,
            corpoHeader: ''
        }
    },
    methods: {
        backToCorpo: function() {
            this.$router.push({ name: 'corporations' })
        },
        goToEditCorporation: function () {
            this.currenttab = 0
        },
        goToProductConfiguration: function() {
            this.currenttab = 1
        },
        goToConfirmationProcess: function() {
            this.currenttab = 2
        },
        goToFieldConfig: function() {
            this.currenttab = 3
        }
    },
    mounted() {
        // if the page is reloaded while in the navigation case, the vuex store will return null
        // BEFORE navigating, so we need to check for that, or else there is an error
        // and the page stops loading
        if (this.corporation != null) {
            // define the header
            var header = this.corporation.name + " (" + this.corporation.nr + ")"
            // add the zip code if available
            if (this.corporation.zip.trim() !== "" && this.corporation.city.trim() !== "") {
                header += " - " + this.corporation.zip + " " + this.corporation.city
            }
            this.corpoHeader = header
        }
    },
    components: {
        EditCorporation,
        ProductConfiguration,
        ConfirmationProcess,
        FieldConfig
    },
    computed: {
        corporation() {
            return this.$store.state.editcorporation
        }
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.page-nav-container {
    width: 100%;
    text-align: center;
    display: flex;
}

.tab-nav-container {
    display: flex;
    align-items: center;
}

.tab-nav-container {
    margin: 0 auto;
    background-color: var(--color-bg-default);
    box-sizing: border-box;
    /* box-shadow: var(--shadow-default); */
    font-weight: bold;
    border-radius: var(--setting-border-radius-big-default);
    overflow: hidden;
}

@media(max-width: 829px) {
    .tab-nav-button {
        padding: 5px 10px;
        line-height: 20px;
    }
}

@media(min-width: 830px) {
    .tab-nav-button {
        line-height: 40px;
        padding: 0 10px;
    }
}

.tab-nav-button {
    height: 100%;
    min-height: 40px;
    border-right: 5px solid var(--color-fg-default);
    box-sizing: border-box;
}

.tab-nav-button:hover {
    cursor: pointer;
    background-color: var(--color-hover-highlight-20);
}

.tab-nav-button > div {
    margin: auto;
    width: 100%;
    height: fit-content;
}

.tab-nav-button.first {
    padding-left: 15px;
}

.tab-nav-button.last {
    padding-right: 15px;
    border-right-color: transparent;
}

.tab-nav-button.highlight {
    background-color: var(--color-highlight-default);
    color: var(--color-font-highlight-default)
}

.page-content-container {
    margin-top: 25px
}
</style>