<template>
    <div class="switch-container" :class="{ 'big' : this.big }">
        <span v-if="label && !labelright" class="label left">Active</span>
        <label class="switch" :class="{ 'big' : this.big }">
            <input type="checkbox" v-model="checked" @change="handleChange">
            <span class="slider" :class="{ 'big' : this.big }"></span>
        </label>
        <span v-if="label && labelright" class="label right">Active</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Active'
        },
        label: {
            type: Boolean,
            default: true
        },
        labelright: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        big: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checked: false
        }
    },
    mounted() {
        this.checked = this.active
    },
    methods: {
        handleChange: function() {
            this.$emit('change', this.checked)
        }
    },
}
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.switch.big {
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    /* background-color: white; */
    background-color: var(--color-font-contrast-dark-default);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

.slider.big:before {
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
}

input:checked + .slider {
    background-color: var(--color-font-contrast-dark-default);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
    background-color: white;
}

input:checked + .slider.big:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.switch-container.big {
    line-height: 17px;
}

.switch-container.big {
    line-height: 34px;
}

.label.left {
    margin-right: 20px;
}

.label.right {
    margin-left: 20px;
}
</style>