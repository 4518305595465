<template>
  <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div  class="card-container shimmer" v-for="contact in contactloadinglist" :key="contact.id">
            </div>
        </div>
        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="contact in this.contactlist" :key="contact.id" @click="goToEdit(contact)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="user" scale="3" />
                    </div>
                    <div class="card-title-container">
                        <p class="role">{{ getRoleName(contact.roleid) }}</p>
                        <p class="designation">{{ contact.username }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newContact">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('contacts.newcontact') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewcontact" :isloading="isloadingsavenewcontact" :isopen="shownewcontact" @close="hideModal" :title="$t('contacts.newcontact')" :submitlabel="$t('contacts.buttons.save')" :cancellabel="$t('contacts.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('contacts.username')" :isloading="isloadingsavenewcontact" v-model="newcontact.username" :error="$v.newcontact.username.$error" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingDropdown :title="$t('contacts.role')" :isloading="isloadingsavenewcontact" :optionlist="roles" :selected="newcontact.roleid.toString()" @change="roleChange" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingDropdown :title="$t('contacts.salutation')" :isloading="isloadingsavenewcontact" :optionlist="salutations" :selected="newcontact.salutationid.toString()" @change="salutationChange" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('contacts.firstname')" :isloading="isloadingsavenewcontact" v-model="newcontact.firstname" :error="$v.newcontact.firstname.$error" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('contacts.lastname')" :isloading="isloadingsavenewcontact" v-model="newcontact.lastname" :error="$v.newcontact.lastname.$error" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('contacts.newpassword')" :isloading="isloadingsavenewcontact" v-model="newcontact.password" :error="$v.newcontact.password.$error" :password="true" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('contacts.confirmpassword')" :isloading="isloadingsavenewcontact" v-model="confirmpassword" :error="$v.confirmpassword.$error" :password="true" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingInput from '../components/systems/elements/FloatingInput'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required, sameAs } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloadingcontacts: false,
            isloadingsalutations: false,
            isloadingtitles: false,
            isloadingroles: false,
            isloadingsavenewcontact: false,
            contactlist: [],
            contactloadinglist: [{id: 0}, {id: 1}, {id: 2}],
            shownewcontact: false,
            newcontact: {
                username:  null,
                firstname: null,
                lastname:  null,
                password:  null
            },
            confirmpassword: null
        }
    },
    validations: {
        newcontact: {
            username:  { required },
            firstname: { required },
            lastname:  { required },
            password:  { required }
        },
        confirmpassword: { 
            sameAsPassword: sameAs(function() { return this.newcontact.password })
        }
    },
    computed: {
        isloading() {
            return this.isloadingcontacts || this.isloadingsalutations || this.isloadingtitles ||this.isloadingroles
        },
        roles() {
            return this.$store.state.roles
        },
        salutations() {
            return this.$store.state.salutations
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadRoles()
            this.getContacts()
            this.loadSalutations()
            this.loadTitles()
        },
        roleChange: function(event) {
            this.newcontact.roleid = event.displayid
        },
        salutationChange: function(event) {
            this.newcontact.salutationid = event.displayid
            this.newcontact.salutation = event.displayname
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingsavenewcontact = true

                // save new contact
                // POST '/clients/:clientid/contacts'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/contacts/'

                var body = {
                    contact: this.newcontact
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingsavenewcontact = false
                    } else {
                        this.resetNewContact()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            } else {
                this.resetNewContact()
            }
        },
        resetNewContact: function() {
            this.isloadingsavenewcontact = false
            this.newcontact = null
            this.confirmpassword = null
            this.shownewcontact = false
        },
        newContact: function() {
            this.confirmpassword = ''

            this.newcontact = {
                firstname: '',
                lastname: '',
                roleid: this.roles[0].displayid,
                username: '',
                salutationid: this.salutations[0].displayid,
                salutation: this.salutations[0].displayname,
                password: ''
            }

            this.isloadingsavenewcontact = false
            this.shownewcontact = true
        },
        getRoleName: function(roleid) {
            if (this.roles != null) {
                var role = this.roles.find(role => role.displayid == roleid)
                if (role) {
                    return role.displayname
                } else {
                    return ''
                }
                
            }
        },
        getContacts: function() {
            this.isloadingcontacts = true
            this.contactlist = [{ id: 0 },{ id: 1 }]

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/contacts'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.contactlist = responseBody.data.contactlist
                    this.isloadingcontacts = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
                this.isloadingcontacts = false
            })
        },
        goToEdit: function(contact) {
            this.$store.commit('newEditContact', contact)
            this.$router.push({ name: 'editcontact' })
        },
        loadSalutations: function() {
            this.isloadingsalutations = true

            if (this.salutations != null && this.salutations.length != 0) {
                // load them as few times as possible
                this.isloadingsalutations = false
                return
            }

            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/salutations'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    var salutationoptions = data.salutationlist.map(entry => {
                        return { displayid: entry.IDAnrede, displayname: entry.AnredeText}
                    })

                    this.$store.commit('newSalutations', salutationoptions)
                }

                this.isloadingsalutations = false
            })
            .catch(err => {
                // dont stop loading? since saving now might result in bad data
                Vue.$toast.error(err)
            })
        },
        loadTitles: function() {
            this.isloadingtitles = true

            var titlecache = this.$store.state.titles

            if (titlecache != null && titlecache.length != 0) {
                // load them as few times as possible
                this.isloadingtitles = false
                return
            }

            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/titles'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    var titleoptions = data.titlelist.map(entry => {
                        return { displayid: entry.IDTitel, displayname: entry.TitelText}
                    })

                    this.$store.commit('newTitles', titleoptions)
                }

                this.isloadingtitles = false
            })
            .catch(err => {
                // dont stop loading? since saving now might result in bad data
                Vue.$toast.error(err)
            })
        },
        loadRoles: function() {
            this.isloadingroles = true

            if (this.roles != null && this.roles.length != 0) {
                // load them as few times as possible
                this.isloadingroles = false
                return
            }

            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/roles'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    var roleoptions = data.rolelist.map(entry => {
                        return { displayid: entry.IDRolle, displayname: entry.Bezeichnung}
                    })

                    this.$store.commit('newRoles', roleoptions)
                }

                this.isloadingroles = false
            })
            .catch(err => {
                // dont stop loading? since saving now might result in bad data
                Vue.$toast.error(err)
            })
        }
    },
    components: {
        Modal,
        FloatingInput,
        FloatingDropdown
    }
}
</script>

<style scoped>
.designation {
    margin-top: 20px;
}

.role {
    margin-bottom: 15px;
}
</style>