<template>
    <div>
        <DataTitle :title="$t('corporations.header.logo')" />
        <div class="data-container">
            <div class="input-container">
                <LogoUpload :loading="isloadinglogo" :logo="logo" @upload="uploadImage" :hint="$t('corporations.uploadhint')"/>
            </div>
        </div>
        <DataTitle :title="$t('corporations.header.details')" />
        <div class="data-container" v-if="corporation != null">
            <div class="input-container two-elements">
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.name')" :error="$v.corporation.name.$error" v-model="corporation.name" :isrequired="true"/>
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.nr')" :error="$v.corporation.nr.$error" v-model="corporation.nr" :isrequired="true" class="nr-input" :toggleDisplay="editMode" />
                <div v-if="isloadingdata" class="input-line-loading-placeholder shimmer big"></div>
            </div>
            <div class="input-container two-elements">
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.street')" v-model="corporation.street" :toggleDisplay="editMode"/>
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.housenr')" v-model="corporation.housenr" class="nr-input" :toggleDisplay="editMode" />
                <div v-if="isloadingdata" class="input-line-loading-placeholder shimmer big"></div>
            </div>
            <div class="input-container two-elements">
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.zip')" v-model="corporation.zip" class="nr-input" :toggleDisplay="editMode"/>
                <FloatingInput v-if="!isloadingdata" :title="$t('corporations.city')" v-model="corporation.city" :toggleDisplay="editMode" />
                <div v-if="isloadingdata" class="input-line-loading-placeholder shimmer big"></div>
            </div>
        </div>

        <div class="button-container">
            <input :disabled="isloadingdata || isloadinglogo" type="button" class="button" :value="$t('corporations.buttons.save')" @click="saveValues" />
        </div>
    </div>
</template>

<script>
import DataTitle from './systems/elements/DataTitle'
import FloatingInput from './systems/elements/FloatingInput'
import LogoUpload from './systems/LogoUpload.vue'

import getEnv from '@/utils/env.js'

import axios from 'axios'
import Vue from 'vue'

import { required } from 'vuelidate/lib/validators'

export default {
    name: 'EditCorporation',
    data() {
        return {
            isloadinglogo: false,
            isloadingdata: false,
            editMode: false
        }
    },
    validations: {
        corporation: {
            name: { required },
            nr: { required }
        }
    },
    mounted() {
        if (this.corporation !== null) {
            this.getCoporationData()
            this.getCorporationLogo()
        }
    },
    computed: {
        corporation() {
            return this.$store.state.editcorporation
        },
        logo: {
            get() {
                return this.$store.state.editcorporationlogo
            },
            set(val) {
                this.$store.state.editcorporationlogo = val
            }
        }
    },
    methods: {
        getCoporationData: function() {
            // TODO: right now there is no single service that returns the coporation
            // so we have to live with the copy from the list in the store
        },
        getCorporationLogo: function() {
            this.isloadinglogo = true
            
            // GET /clients/:clientid/corporations/:corporationid/logo'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/corporations/' + this.corporation.id
            url += '/logo'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode == 9) {
                    // errCode 9 == no picture set
                    Vue.$toast.warning(status.ErrorMsg)
                    this.$store.commit('newEditCorporationLogo', '')
                } else if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    this.$store.commit('newEditCorporationLogo', '')
                } else {
                    this.$store.commit('newEditCorporationLogo', data.logo)
                }

                this.isloadinglogo = false
            })
            .catch(function(err) {
                Vue.$toast.error(err)
                this.isloadinglogo = false
            })
        },
        saveValues: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                Vue.$toast.error(this.$t('corporations.requiredmissing'))
                return
            } else {
                this.saveLogo()
                this.saveData()
            }
        },
        saveData: function() {
            this.isloadingdata = true

            var sessionData = Vue.getSessionData()
            
            // PUT /clients/:clientid/corporations/:corporationid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/corporations/' + this.corporation.id

            var putbody = {
                corporation: this.corporation
            }

            axios.put(url, putbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with the saved corpo
                    //       so we have to push the current corporation back into the editCorporation
                    this.$store.commit('newEditCorporation', this.corporation)
                }

                this.isloadingdata = false
            })
            .catch(function(err) {
                this.isloadingdata = false
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'corporations'})
            })
        },
        uploadImage: function(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.onload = loadEvent => {
                this.logo = loadEvent.target.result;
            }

            reader.readAsDataURL(image)
        },
        saveLogo: function() {
            this.isloadinglogo = true

            // '/clients/:clientid/corporations/:corporationid/logo'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/corporations/' + this.corporation.id
            url += '/logo'

            var postbody =
                {
                    imgdata: this.logo
                }

            axios.post(url, postbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    this.logo = ''
                }

                this.isloadinglogo = false
            })
            .catch(function(err) {
                this.isloadinglogo = false
                this.logo = ''
                Vue.$toast.error(err)
            })
        }
    },
    components: {
        LogoUpload,
        DataTitle,
        FloatingInput
    },
}
</script>

<style scoped>
.input-container.two-elements > div:nth-child(2) {
    margin-left: 10px;
}

.data-container {
    margin-top: 30px;
}

.nr-input {
    width: 150px;
}

.button-container {
    margin-top: 50px;
    text-align: left;
    width: calc(90% - 10px);
    margin-left: auto;
    margin-right: auto;
}
</style>