<template>
  <div id="app">
    <router-view />
    <!-- navbar as last item for stacking context (always overlaying) -->
    <NavBar title="AboOnline Konfiguration" />
    <LanguageChanger class="lang-change"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import LanguageChanger from './components/systems/elements/LanguageChanger.vue'

export default {
    name: 'App',
    components: {
        NavBar,
        LanguageChanger
    }
}
</script>

<style>
@import './assets/css/application.css';
@import './assets/css/toasts.css';

.lang-change {
    position: fixed;
    bottom: 5px;
    left: 5px;
    z-index: 1000;
}

@media(max-width: 1000px) {
    .lang-change {
        bottom: unset;
        top: 70px;
        left: unset;
        right: 5px;
    }
}
</style>
