<template>
    <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="template in this.templatesloadinglist" :key="template.id"></div>
        </div>

        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="template in this.templatelist" :key="template.ID" @click="goToEdit(template)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="font" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ template.VorlageType }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newTemplate">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('mailtemplates.newtemplate') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewtemplate" :isloading="isloadingnewtemplate" :isopen="shownewtemplate" @close="hideModal" :title="$t('mailtemplates.newtemplate')" :submitlabel="$t('mailtemplates.buttons.save')" :cancellabel="$t('mailtemplates.buttons.abort')">
            <template v-slot:content>
                <FloatingDropdown :title="$t('mailtemplates.templatetype')" :isloading="isloadingnewtemplate" :error="$v.newtemplate.VorlageType.$error" :optionlist="availabletypes" :defaultoption="true" :selected="newtemplate.VorlageType.toString()" @change="typeChange" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal.vue'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'

import getEnv from '@/utils/env.js'

import 'vue-awesome/icons/font'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloading: false,
            templatesloadinglist: [{id: 0}, {id: 1}, {id: 2}],
            templatelist: [],
            shownewtemplate: false,
            isloadingnewtemplate: false,
            newtemplate: null,
            templatetypeoptions: [],
            usedtypes: []
        }
    },
    validations: {
        newtemplate: {
            // Betreff: { required },
            VorlageType: { 
                required, 
                isNotDefault: (value) => {return value !== -1}
            }
        }
    },
    computed: {
        availabletypes: function() {
            return this.templatetypeoptions.filter(type => {
                return !this.usedtypes.includes(type.displayid)
            })
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadTemplates()
        },
        typeChange: function(event) {
            this.newtemplate.VorlageType = event.displayid
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewtemplate = true

                // save new template
                // POST '/basedata/mail/templates'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL')
                url += '/basedata/mail/templates'

                var body = {
                    template: this.newtemplate,
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.this.isloadingnewtemplate = false
                    } else {
                        this.resetNewTemplate()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })

            } else {
                this.resetNewTemplate()
            }
        },
        resetNewTemplate: function() {
            this.shownewtemplate = false
            this.newtemplate = null
            this.templatetypeoptions = []
            this.isloadingnewtemplate = false
        },
        newTemplate: function() {
            this.shownewtemplate = true,
            this.newtemplate = {
                Betreff: '',
                VorlageType: -1
            }

            this.isloadingnewtemplate = true

            // GET '/basedata/mail/templates/types'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/mail/templates/types'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.templatetypeoptions = data.templatetypes

                    this.isloadingnewtemplate = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(template) {
            this.$store.commit('newEditMailTemplate', template)
            this.$router.push({ name: 'editmailtemplate'})
        },
        loadTemplates: function() {
            this.isloading = true

            // GET '/basedata/mail/templates'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/mail/templates'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.templatelist = data.templatelist
                    this.usedtypes = data.templatelist.map(template => template.VorlageType)
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        }
    },
    components: {
        Modal,
        // FloatingInput,
        FloatingDropdown
    }
}
</script>

<style scoped>
</style>