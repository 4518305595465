<template>
    <div class="title-container">
        <label>{{ title }}</label>
    </div>
</template>

<script>
export default {
    name: 'DataTitle',
    props: {
        title: {
            type: String,
            default: 'Give me a title'
        }
    }
}
</script>

<style scoped>
.title-container {
    padding: 0 10px;
    text-align: left;
    height: 25px;
    line-height: 25px;
    margin-top: 20px;
    font-size: 1.1em;
    border-bottom: 1px solid var(--color-highlight-greyscale);
}

label {
    color: var(--color-font-header-default);
}
</style>