<template>
    <div class="flip-container" :class="{ 'flip' : isLoading }">
        <div class="flipper">
            <div class="login-container front">
                <div class="title-container">
                    <label>{{ title }}</label>
                    <div class="title-delimiter"></div>
                </div>
                
                <div v-if="!urlclient" class="content-container first">
                    <FloatingInput v-model="client" :title="$t('login.client')" :onenter="login"/>
                    <!-- <input type="text" v-model="username" placeholder="Username..." v-on:keyup.enter="login"/> -->
                </div>

                <div class="content-container" :class="{ 'first' : urlclient }">
                    <FloatingInput v-model="username" :title="$t('login.username')" :onenter="login"/>
                    <!-- <input type="text" v-model="username" placeholder="Username..." v-on:keyup.enter="login"/> -->
                </div>

                <div class="content-container">
                    <FloatingInput v-model="password" :title="$t('login.password')" :password="true" :onenter="login"/>
                    <!-- <input type="password" v-model="password" placeholder="Password..." v-on:keyup.enter="login"/> -->
                </div>
                
                <div class="content-container button-container">
                    <input id="loginBtn" type="button" class="button login-button" :value="$t('login.buttons.login')" @click="login"/>
                </div>
            </div>
            <div class="login-container back">
                <div class="title-container">
                    <label>{{ title }}</label>
                    <div class="title-delimiter"></div>
                </div>

                <div class="content-container first">
                    <label>{{ loadingText }}</label>
                </div>

                <div class="content-container">
                    <Spinner />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Spinner from './systems/elements/Spinner.vue'
import FloatingInput from './systems/elements/FloatingInput.vue'

import getEnv from '@/utils/env.js'

export default {
    name: 'LoginWidget',
    props: {
        title: {
            type: String,
            default: 'Login'
        },
        loadingText: {
            type: String,
            default: ''
        },
        onSuccess: {

        }
    },    
    data() {
        return {
            client: '',
            username: '',
            password: '',
            isLoading: false,
            errorMsg: ''
        }
    },
    computed: {
        urlclient: function() {
            return this.$route.query.client
        },
        resetclient: function() {
            let hasurlclient = this.urlclient && this.urlclient !== ''
            let hasdefaultclient = Vue.getDefaultClient() && Vue.getDefaultClient() !== ''
            return !hasurlclient && hasdefaultclient
        }
    },
    mounted() {
        // if the url is called without the client on purpose, reset the default client
        if (this.resetclient) Vue.resetDefaultClient()
        if (this.urlclient) this.client = this.urlclient
    },
    methods: {
        login: function() {
            this.isLoading = true;

            var sessionCreationTime = new Date().getTime()
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_LOGIN
            url += '?username=' + encodeURIComponent(this.username)
            url += '&password=' + encodeURIComponent(this.password)
            url += '&client=' + this.client

            // 20 sec timeout
            axios.get(url).then(response => {
                var responseBody = response.data
                var towsStatus = responseBody.status

                if (towsStatus.ErrorCode != 0) {
                    throw towsStatus.ErrorMsg

                } else {
                    var sessionId = responseBody.data.sessionid
                    var clientid = responseBody.data.clientid
                    // new login always overwrites old session
                    Vue.setSessionData(sessionCreationTime, sessionId, this.client, clientid, this.username)
                    this.emitSuccess()
                }

                this.isLoading = false
            }).catch(err => {
                this.emitError(err)
                this.isLoading = false
            })
        },
        emitError: function(msg) {
            this.$emit('error', msg)
        },
        emitSuccess: function() {
            this.$emit('success')
        }
    },
    components: {
        Spinner,
        FloatingInput
    }
}
</script>

<style scoped>
.flip-container.flip .flipper {
    transform: rotateY(180deg);
}

/* front pane, placed above back */
.login-container.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.login-container.back {
    transform: rotateY(180deg);
}

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.login-container {
    width: 300px;
    height: 400px;
    background-color: var(--color-fg-default);
    margin: 20vh auto;
    border-radius: var(--setting-border-radius-big-default);
    padding-top: 25px;
    box-sizing: border-box;
    position: relative;
    box-shadow: var(--shadow-default);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.title-container {
    font-size: 1.5em;
    font-weight: bold;
    width: 80%;
    margin: 0 auto;
}

.title-container label {
    color: var(--color-font-header-default);
}

.title-delimiter {
    margin-top: 20px;
    width: 100%;
    border: 1px solid var(--color-font-header-default);
}

.content-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 25px;
}

.content-container.first {
    margin-top: 50px;
}

.login-button {
    /* width: 100px; */
    margin: 0 auto;
}

.content-container.button-container {
    position: absolute;
    bottom: 30px;
    width: 100%
}

input {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
}
</style>