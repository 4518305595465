<template>
    <div class="list-container">
        <div class="list-wrapper" v-if="!isloading">
            <div class="list" v-for="entry in displaylist" :key="entry.displayid" @click="toggleEntry(entry)">
                <div class="item" >
                    <div class="row">
                        <div class="column left" :class="{ 'active' : entry.active }">
                            <v-icon class="check-icon" :class="{ 'active' : entry.active }" :name="entry.active ? 'check' : 'times'" scale="1"></v-icon>
                        </div>
                        <div class="column right" :class="getLongTextClass(entry.displayname)">
                            <label class="text">{{ entry.displayname }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-wrapper" v-if="isloading">
            <div class="list" v-for="entry in loadinglist" :key="entry.displayid" >
                <div class="item loading" >
                    <div class="row loading shimmer"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/times'

export default {
    name: 'CheckboxList',
    props: {
        isloading: {
            type: Boolean,
            default : true
        },
        displaylist: {
            type: Array,
        }
    },
    data() {
        return {
            loadinglist: [
                { displayid: 1, displayname: '', active: false },
                { displayid: 2, displayname: '', active: false },
                { displayid: 3, displayname: '', active: false },
                { displayid: 4, displayname: '', active: false }
            ]
        }
    },
    methods: {
        getLongTextClass: function(displayname) {
            if (displayname.length > 33) {
                return 'long'
            } else if (displayname.length > 66) {
                return 'extra-long'
            } else {
                return 'short'
            }
        },
        toggleEntry: function(entry) {
            entry.active ^= 1
            this.$emit('toggle', entry)
        }
    }
}
</script>

<style scoped>
.list-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 500px;
}


.list-container {
    width: fit-content;
    height: fit-content;
    margin: auto;
}

.item {
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
    box-shadow: var(--shadow-default);
    border-radius: var(--setting-border-radius-big-default);
    overflow: hidden;
}

.row {
  display: flex;
  height: 100%;
}

.row.loading {
    width: 360px;
}

.row:hover,
.row:hover >>> * {
    cursor: pointer;
}

.row:hover {
    background: var(--color-hover-highlight-10);
    box-shadow: var(--shadow-highlight);
}

.column.left {
    width: 60px;
    line-height: 30px;
    background: var(--color-diasbled-default);
}

.column.left.active {
    background: var(--color-highlight-default);
}

.check-icon {
    font-size: 3em !important;
    color: var(--color-hover-highlight-20);
}

.check-icon.active {
    color: var(--color-font-highlight-default);
}

.column.right {
    width: 300px;
    line-height: 30px;
    text-align: left;
    padding-left: 10px;
    box-sizing: border-box;
}

.column.right.long {
    font-size: 0.75em;
    line-height: 15px;
}

.column.right.extra-long {
    font-size: 0.6em;
    line-height: 15px;
}

.fa-icon {
    vertical-align: -3px;
}
</style>