<template>
  <div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToContacts()">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $tc('contacts.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.contactheader }}</label>
                </div>
            </div>

            <DataTitle :title="$t('contacts.header.details')" />
            <div class="data-container" v-if="contact != null">
                <div class="input-container no-space">
                    <div class="label-container" v-if="!isloading">
                        <label>{{ $t('contacts.active') }}</label>
                    </div>
                    <ToggleButton v-if="!isloading" :active="contact.active" :label="false" @change="activeChanged"/>
                    <div v-else class="input-line-loading-placeholder shimmer big"></div>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.username')" :error="$v.contact.username.$error" :isrequired="true" v-model="contact.username"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown class="input-row" :isloading="isloading" :isrequired="true" :defaultoption="true" :title="$t('contacts.role')" :error="$v.contact.roleid.$error" :selected="contact.roleid.toString()" defaultoptionid="-1" :optionlist="roleoptions" @change="roleChanged"/>
                </div>
                
                <div class="input-container">
                    <FloatingDropdown class="input-row" :isloading="isloading" :isrequired="true" :defaultoption="true" :title="$t('contacts.salutation')" :error="$v.contact.salutationid.$error" :selected="contact.salutationid" :optionlist="salutationoptions" @change="salutationChanged"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown class="input-row" :isloading="isloading" :defaultoption="true" :title="$t('contacts.title')" :selected="contact.titleid" :optionlist="titleoptions" @change="titleChanged"/>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.firstname')" :error="$v.contact.firstname.$error" :isrequired="true" v-model="contact.firstname"/>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.lastname')" :error="$v.contact.lastname.$error" :isrequired="true" v-model="contact.lastname"/>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.email')" v-model="contact.mail"/>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.phone')" v-model="contact.phone"/>
                </div>

                <div class="input-container">
                    <FloatingInput class="input-row" :isloading="isloading" :title="$t('contacts.fax')" v-model="contact.fax"/>
                </div>

                <div class="input-container">
                    <FloatingTextarea class="input-row" :isloading="isloading" :title="$t('contacts.additionalinformation')" v-model="contact.note"/>
                </div>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="submit" class="button" :value="$t('contacts.buttons.save')" @click="saveValues" />
                <input :disabled="isloading" type="button" class="button" :value="$t('contacts.buttons.changepassword')" @click="changePassword">
                <input :disabled="isloading" type="button" class="button delete" :value="$t('contacts.buttons.delete')" @click="deleteContact">
            </div>
        </div>
        <Modal v-if="showpasswordchange" :isopen="showpasswordchange" @close="hideModal" :title="$t('contacts.header.changepassword')" :submitlabel="$t('contacts.buttons.changepassword')" :cancellabel="$t('contacts.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('contacts.newpassword')" :isrequired="true" :password="true" v-model="newpassword"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('contacts.confirmpassword')" :isrequired="true" :password="true" v-model="newpasswordconfirmation" />
            </template>
        </Modal>
        <Modal v-if="showdeleteareyoursure" :isopen="showdeleteareyoursure" @close="hideDeleteAreYouSure" :title="$t('contacts.header.delete')"/>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle.vue'
import FloatingInput from '../components/systems/elements/FloatingInput.vue'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'
import FloatingTextarea from '../components/systems/elements/FloatingTextarea.vue'
import Modal from '../components/systems/elements/Modal.vue'

import ToggleButton from '../components/systems/elements/ToggleButton'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.editcontact === null) {
                next('/contacts')
            } else {
                next()
            }
        })
    },
    data() {
        return {
            contactheader: '',
            isloading: false,
            showpasswordchange: false,
            newpassword: '',
            newpasswordconfirmation: '',
            showdeleteareyoursure: false
        }
    },
    validations: {
        contact: {
            username: { required },
            roleid: { 
                required,
                notdefault: (val) => val >= 0
            },
            salutationid: {
                required,
                notdefault: (val) => val >= 0
            },
            firstname: { required },
            lastname: { required }
        }
    },
    computed: {
        contact() {
            return this.$store.state.editcontact
        },
        salutationoptions() {
            return this.$store.state.salutations
        },
        titleoptions() {
            return this.$store.state.titles
        },
        roleoptions() {
            return this.$store.state.roles
        }
    },
    methods: {
        deleteContact: function() {
            this.showdeleteareyoursure = true
        },
        hideDeleteAreYouSure: function(submit) {
            
            if (submit) {
            const sessionData = Vue.getSessionData()

                // TODO: delete contact
                let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + sessionData.clientid
                url += '/contacts/' + this.contact.id

                var body = {
                    contact: this.contact
                }

                axios.post(url, body)
                .then(response => {
                    var responseBody = response.data
                    var status = responseBody.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                    } else {
                        Vue.$toast.info(this.$t('contacts.deletesuccess'))
                        this.$router.push({ name: 'contacts' })
                    }

                    this.isloading = false
                })
                .catch(err => {
                    Vue.$toast.error(err)
                    this.isloading = false
                    // navigate back so the data is kept up to date
                    this.$router.push({ name: 'contacts '})
                })
            } else {
                this.showdeleteareyoursure = false
            }
        },
        backToContacts: function() {
            this.$router.push({ name: 'contacts'})
        },
        salutationChanged: function(event) {
            // event == option
            this.contact.salutationid = event.displayid
            this.contact.salutation = event.displayname
        },
        titleChanged: function(event) {
            // event == option
            this.contact.titleid = event.displayid
            this.contact.title = event.displayname
        },
        roleChanged: function(event) {
            // event == option
            this.contact.roleid = event.displayid
        },
        activeChanged: function(event) {
            // event == toggle state == true/false
            this.contact.active = event
        },
        saveValues: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                Vue.$toast.error(this.$t('contacts.requiredmissing'))
                return
            }

            this.isloading = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/contacts/' + this.contact.id

            var putbody = {
                contact: this.contact
            }

            axios.put(url, putbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with the saved values
                    //       so we have to push the current one back into the store
                    // --> ALSO we always set the pw to 'Verschlüsselt', else we could
                    // change it multiple times by accident
                    this.contact.password = 'Verschlüsselt'
                    this.$store.commit('newEditContact', this.contact)
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                this.isloading = false
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'contacts '})
            })
        },
        changePassword: function() {
            this.showpasswordchange = true
        },
        hideModal: function(success) {
            if (success) {

                if (!this.newpassword || this.newpassword.length === 0) {
                    Vue.$toast.error(this.$t('contacts.password-missing'))
                    return
                }

                if (this.newpassword !== this.newpasswordconfirmation) {
                    Vue.$toast.error(this.$t('contacts.passwords-do-not-match'))
                    return
                }

                this.contact.password = this.newpassword
                this.saveValues()
            }

            // reset input
            this.newpassword = ''
            this.newpasswordconfirmation = ''

            this.showpasswordchange = false
        }

        // TODO: from the java version:
        // // 20.11.2019, sj, MOB-A477: Kontakt wird "gelöscht", indem er auf "daktiviert" gesetzt wird.
        // if (deaktivieren && towsContact.isAktiv()) {
        // 	towsContact.setAktiv(false);
        // 	towsContact.setUsername(towsContact.getUsername() + "_entfernt_" + new Date().getTime());
        // }
    },
    mounted() {
        // if the page is reloaded while in the navigation case, the vuex store will return null
        // BEFORE navigating, so we need to check for that, or else there is an error
        // and the page stops loading
        if (this.contact != null) {
            // define the header
            this.contactheader = this.contact.username

            if (this.roleoptions != null) {
                var role = this.roleoptions.find(role => role.displayid == this.contact.roleid)
                this.contactheader += " - " + role.displayname
            }
        }
    },
    components: {
        DataTitle,
        FloatingInput,
        FloatingDropdown,
        ToggleButton,
        Modal,
        FloatingTextarea
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}

.label-container {
    margin-right: 10px;
    text-align: left;
}

.delete:not(:disabled) {
    color: red;
}
</style>