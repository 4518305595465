<template>
    <div class="accordion-container">
        <details :open="open">
            <summary>
                <DataTitle :title="title"/>
            </summary>
            <slot></slot>
        </details>
    </div>
</template>

<script>
import DataTitle from '../systems/elements/DataTitle'

export default {
    props: {
        title: String,
        open: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DataTitle
    }
}
</script>

<style scoped>
details {
    position: relative;
}

summary {
    list-style: none;
    margin-bottom: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

summary:hover {
    background: var(--color-hover-highlight-10);
}

summary::marker,
summary::-webkit-details-marker {
    display: none;
}

summary:after {
    content: "🡒";
    font-size: 1.4em;
    position: absolute;
    right: 5px;
    top: -2px;
    transition: all 0.3s;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

details[open] > summary:after {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    /* top: 25px; */
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-0.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

details[open] summary ~ * {
    animation-name: fadeInDown;
    animation-duration: 0.4s;
}
</style>