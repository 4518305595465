import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import i18n from './i18n'
import Icon from 'vue-awesome/components/Icon'
import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'
import Vuelidate from 'vuelidate'
import VueToast from 'vue-toast-notification'
import StorageManipulation from './StorageManipulation'
import axios from 'axios'
import Vuex from 'vuex'

dotenvExpand(dotenv.config())

// register vue-awesome icons
Vue.component('v-icon', Icon)

// register vuelidate for field validation
Vue.use(Vuelidate)

// register and setup toast component
Vue.use(VueToast, {
    position: 'top',
    duration: 7500 // 7.5sec
})

// register storage helper
Vue.use(StorageManipulation)

// configure axios
axios.defaults.timeout = 20000 // default timeout
const HEADER_HQ_TOKEN = 'hq_token'
axios.interceptors.request.use(config => {
    // set sessionid as header in every request
    // if one is present
    var sessionData = Vue.getSessionData()
    if (sessionData && sessionData.token) {
        config.headers[HEADER_HQ_TOKEN] = sessionData.token
    }

    return config
})

// interceptors for to session
axios.interceptors.response.use(response => {
    // if the session from the response equals the current TO session
    // we made a successfull request to the tows and can update the
    // creation time of the sessionData so that the session will not end
    var sessionData = Vue.getSessionData()

    if (response.data.status && sessionData) {
        if (parseInt(response.data.status.ErrorCode) === 3) {
            // ErrorCode 3 --> session has expired
            this.$router.push({ name: 'login' })
        }

        var requestToken = response.data.status.SessionID
        var currentToken = sessionData.token

        if (requestToken == currentToken) {
            Vue.extendSession()
        }
    }

    return response
})

// setup vuex store
// TODO: persist state after refresh? https://stackoverflow.com/a/58841356/9368637
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        statistics: null,
        clientdata: null,
        clientlogo: null,
        corporations: [],
        editcorporation: null,
        editcorporationlogo: null,
        editcontact: null,
        salutations: [],
        titles: [],
        roles: [],
        editchangeoverday: null,
        editattestation: null,
        editlink: null,
        edittext: null,
        editproduct: null,
        editmailtemplate: null,
    },
    mutations: {
        newStatistics: function(state, statistics) {
            state.statistics = statistics
        },
        newClientData: function(state, clientdata) {
            state.clientdata = clientdata
        },
        newClientLogo: function(state, clientlogo) {
            state.clientlogo = clientlogo
        },
        newCorporations: function(state, corporations) {
            state.corporations = corporations
        },
        newEditCorporation: function(state, corporation) {
            state.editcorporation = corporation
        },
        newEditCorporationLogo: function(state, logo) {
            state.editcorporationlogo = logo
        },
        newEditContact: function(state, contact) {
            state.editcontact = contact
        },
        newSalutations: function(state, salutationlist) {
            state.salutations = salutationlist
        },
        newTitles: function(state, titlelist) {
            state.titles = titlelist
        },
        newRoles: function(state, rolelist) {
            state.roles = rolelist
        },
        newEditChangeoverDay: function(state, changeoverday) {
            state.editchangeoverday = changeoverday
        },
        newEditAttestation: function(state, attestation) {
            state.editattestation = attestation
        },
        newEditLink: function(state, link) {
            state.editlink = link
        },
        newEditText: function(state, text) {
            state.edittext = text
        },
        newEditProduct: function(state, product) {
            state.editproduct = product
        },
        newEditMailTemplate: function(state, template) {
            state.editmailtemplate = template
        }
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
