<template>
    <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="product in this.productloadinglist" :key="product.id"></div>
        </div>

        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="product in this.productlist" :key="product.ProduktZuordnungBezeichnung" @click="goToEdit(product)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="ticket-alt" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ product.ProduktZuordnungBezeichnung }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newCofiguration">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('productconfigurations.newconfiguration') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewconfiguration" :isloading="isloadingnewconfiguration" :isopen="shownewconfiguration" @close="hideModal" :title="$t('productconfigurations.newconfiguration')" :submitlabel="$t('productconfigurations.buttons.save')" :cancellabel="$t('productconfigurations.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('productconfigurations.name')" :isloading="isloadingnewconfiguration" v-model="newconfiguration.ProduktZuordnungBezeichnung" :error="$v.newconfiguration.ProduktZuordnungBezeichnung.$error" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('productconfigurations.backendid')" :isloading="isloadingnewconfiguration" v-model="newconfiguration.IDHintergrundsystem" :error="$v.newconfiguration.IDHintergrundsystem.$error" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingDropdown :title="$t('productconfigurations.product')" :isloading="isloadingnewconfiguration" :optionlist="productoptions" :error="$v.newconfiguration.ProduktID.$error" :selected="newconfiguration.ProduktID.toString()" @change="productChange" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingDropdown :title="$t('productconfigurations.tarifftype')" :isloading="isloadingnewconfiguration" :optionlist="tarifftypeoptions" :error="$v.newconfiguration.TarifArt.$error" :selected="newconfiguration.TarifArt" @change="tariffTypeChange" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingDropdown :title="$t('productconfigurations.bankingmode')" :isloading="isloadingnewconfiguration" :optionlist="bankmodeoptions" :error="$v.newconfiguration.BankverbindungsModus.$error" :selected="newconfiguration.BankverbindungsModus" @change="bankModeChange" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingInput from '../components/systems/elements/FloatingInput'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'

import 'vue-awesome/icons/ticket-alt'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloading: false,
            productlist: [],
            productloadinglist: [{id: 0} , {id: 1}, {id: 2}],
            shownewconfiguration: false,
            isloadingnewconfiguration: false,
            newconfiguration: null,
            productoptions: [],
            tarifftypeoptions: [],
            bankmodeoptions: []
        }
    },
    validations: {
        newconfiguration: {
            ProduktZuordnungBezeichnung: { required },
            IDHintergrundsystem: { required },
            ProduktID: {
                required,
                minValue: 0
            },
            TarifArt: {
                required,
                minValue: 0
            },
            BankverbindungsModus: {
                required,
                minValue: 0
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadProducts()
        },
        bankModeChange: function(event) {
            this.newconfiguration.BankverbindungsModus = event.displayid
        },
        tariffTypeChange: function(event) {
            this.newconfiguration.TarifArt = event.displayid
        },
        productChange: function(event) {
            this.newcofiguration.ProduktID = event.displayid
            this.newcofiguration.ProduktNr = event.displayid.toString()
        },
        newCofiguration: function() {
            this.shownewconfiguration = true
            this.isloadingnewconfiguration = true

            // prepare tows object
            this.newconfiguration = {
                IDHintergrundsystem: '',
                ProduktZuordnungBezeichnung: '',
                BankverbindungsModus: '',
                ProduktID: 0,
                ProduktNr: '',
                TarifArt: '',
            }

            // GET '/clients/:clientid/productconfiguration/:productid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/productconfiguration/' + '0'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.productoptions = data.productlist.map(entry => {
                        return { displayid: entry.ProduktID, displayname: entry.ProduktBezeichnung }
                    })

                    this.tarifftypeoptions = data.tarifftypelist
                    this.bankmodeoptions = data.bankmodelist

                    this.newconfiguration.BankverbindungsModus = this.bankmodeoptions[0].displayid,
                    this.newconfiguration.ProduktID = this.productoptions[0].displayid,
                    this.newconfiguration.ProduktNr = this.productoptions[0].displayid,
                    this.newconfiguration.TarifArt = this.tarifftypeoptions[0].displayid,

                    this.isloadingnewconfiguration = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewconfiguration = true

                // save new configuration
                // POST '/clients/:clientid/productconfiguration'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/productconfiguration'

                var body = {
                    configuration: this.newconfiguration
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingnewconfiguration = false
                    } else {
                        this.resetNewConfiguration()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })

            } else {
                this.resetNewConfiguration()
            }
        },
        resetNewConfiguration: function() {
                this.newconfiguration = null
                this.productoptions = []
                this.tarifftypeoptions = []
                this.bankmodeoptions = []
                this.isloadingnewconfiguration = false
                this.shownewconfiguration = false
        },
        goToEdit: function(product) {
            this.$store.commit('newEditProduct', product)
            this.$router.push({ name: 'editproductconfiguration' })
        },
        loadProducts: function() {
            this.isloading = true

            // GET '/clients/:clientid/products'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/products'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.productlist = data.productlist
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
    },
    components: {
        Modal,
        FloatingInput,
        FloatingDropdown
    }
}
</script>

<style scoped>
</style>