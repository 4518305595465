<template>
    <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="attestation in this.attestationloadinglist" :key="attestation.id"></div>
        </div>
        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="attestation in this.attestationlist" :key="attestation.IDUmschalttag" @click="goToEdit(attestation)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="file-contract" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ attestation.Bezeichnung }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newAttestation">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('attestations.newattestation') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewattestation" :isloading="isloadingnewattestation" :isopen="shownewattestation" @close="hideModal" :title="$t('attestations.newattestation')" :submitlabel="$t('attestations.buttons.save')" :cancellabel="$t('attestations.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('attestations.name')" :isloading="isloadingnewattestation" v-model="newattestation.Bezeichnung" :error="$v.newattestation.Bezeichnung.$error" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingInput from '../components/systems/elements/FloatingInput'

import 'vue-awesome/icons/file-contract'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloading: false,
            attestationloadinglist: [{id:0}, {id: 1}, {id: 2}],
            attestationlist: [],
            shownewattestation: false,
            isloadingnewattestation: null,
            newattestation: null
        }
    },
    validations: {
        newattestation: {
            Bezeichnung: { required }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadAttestations()
        },
        newAttestation: function() {
            this.shownewattestation = true
            this.newattestation = {
                Bezeichnung : ''
            }
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()
                
                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewattestation = true

                // save new attestation
                // POST #/clients/:clientid/attestations'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/attestations'

                var body = {
                    attestation: this.newattestation
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingnewattestation = false
                    } else {
                        this.resetNewAttestation()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            } else {
                this.resetNewAttestation()
            }
        },
        resetNewAttestation: function() {
            this.shownewattestation = false
            this.newattestation = null
        },
        loadAttestations: function() {
            this.isloading = true

            // GET '/clients/:clientid/attestations'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/attestations'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.attestationlist = data.attestationlist
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(attestation) {
            this.$store.commit('newEditAttestation', attestation)
            this.$router.push({ name: 'editattestation'})
        }
    },
    components: {
        Modal,
        FloatingInput
    }
}
</script>

<style scoped>
</style>