  <template>
    <div id="page-container" class="has-menu">
        <div class="card-container-wrapper">
            <div class="card-container client-container" :class="{ 'shimmer big' : isLoading}" @click="goToClient">
                <div v-if="isLoading" class="loading-container">
                    <LoadingSpinner />
                </div>

                <div v-if="!isLoading" class="client-content-container">
                    <div class="client-title-container">
                        <label>{{ $t('dashboard.client') }}</label>
                    </div>

                    <div class="client-content">
                        <div class="client-content-half left">
                            <p>{{ this.statistics.name }}</p>
                            <p>{{ this.statistics.abbreviation }}</p>
                            <p>{{ this.statistics.email }}</p>
                            <p>{{ this.statistics.phone }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-container" v-for="option in optionlist" :key="option.cardid" :class="{ 'shimmer' : isLoading }">
                <div v-if="!isLoading">
                    <!-- <img height="70" width="70" class="card-icon-alt" :src="getImageSrc(option.iconalt)"> -->
                    <v-icon class="card-icon" :name="option.icon" scale="4" />
                    <div class="title-container">
                        <label>{{ $t(option.title) }}</label>
                    </div>
                    <div class="count-container" >
                        <label>{{ getValueToOption(option.cardid) }}</label>
                    </div>
                </div>
            </div>

            <div class="card-container undo" :class="{ 'shimmer' : isLoading }" @click="resetStatistic">
                <div v-if="!isLoading">
                    <v-icon class="card-icon" name="undo" scale="4" />
                    <div class="title-container">
                        <label>{{ $t('dashboard.reset') }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

import 'vue-awesome/icons/ticket-alt'
import 'vue-awesome/icons/exchange-alt'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/money-check-alt'
import 'vue-awesome/icons/portrait'
import 'vue-awesome/icons/balance-scale-right'
import 'vue-awesome/icons/tasks'
import 'vue-awesome/icons/receipt'
import 'vue-awesome/icons/undo'

import LoadingSpinner from '../components/systems/elements/Spinner.vue'

import getEnv from '@/utils/env.js'

import axios from 'axios'

export default {
    name: 'DashboardPage',
    mounted() {
        this.getStatistics()
    },
    methods: {
        resetStatistic: function() {
            this.isLoading = true;

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_DASHBOARD_INFO
            url += '/' + sessionData.clientid

            axios.delete(url)
            .then(/*response*/ () => {
                this.getStatistics()
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToClient: function() {
            this.$router.push('/clientdata')
        },
        getStatistics: function() {
            this.isLoading = true;

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_DASHBOARD_INFO
            url += '/' + sessionData.clientid

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    this.emitError(status.ErrorMsg)
                } else {
                    this.$store.commit('newStatistics', responseBody.data)
                }

                this.isLoading = false
            })
            .catch(err => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
            })
        },
        getValueToOption: function(cardid) {
            // only try to access list data if it's present
            if (this.statistics.actionlist !== undefined) {
                return this.statistics.actionlist[cardid].Anzahl
            } else {
                return ''
            }

        },
        getImageSrc: function(name) {
            if (!name) {
                return ''
            }
            var icons = require.context('../assets/img/icons/', false, /\.svg$/)
            return icons('./' + name + ".svg")
        },
    },
    data() {
        return {
            isLoading: true,
            optionlist: [
                { cardid: 0, title: 'dashboard.productselection', icon: 'ticket-alt', iconalt: 'Icons_TicketOffice Web_Produktauswahl' },
                { cardid: 1, title: 'dashboard.connection', icon: 'exchange-alt', iconalt: 'Icons_TicketOffice Web_Verbindungssucher' },
                { cardid: 2, title: 'dashboard.personaldata', icon: 'user', iconalt: 'Icons_TicketOffice Web_Persönliche Daten' },
                { cardid: 3, title: 'dashboard.bankdetails', icon: 'money-check-alt', iconalt: 'Icons_TicketOffice Web_Bankverbindung' },
                { cardid: 4, title: 'dashboard.picture', icon: 'portrait', iconalt: 'Icons_TicketOffice Web_Foto' },
                { cardid: 5, title: 'dashboard.legal', icon: 'balance-scale-right', iconalt: 'Icons_TicketOffice Web_AGBs' },
                { cardid: 6, title: 'dashboard.datavalidation', icon: 'tasks', iconalt: 'Icons_TicketOffice Web_Daten prüfen' },
                { cardid: 7, title: 'dashboard.completed', icon: 'receipt', iconalt: 'Icons_TicketOffice Web_Abgeschlossen' },
            ]
        }
    },
    computed: {
        statistics() {
            return this.$store.state.statistics
        },
    },
    components: {
        LoadingSpinner,
    }
}
</script>

<style scoped>
.client-container {
    width: 440px;
    height: 200px;
    margin: 20px;
    background-color: var(--color-fg-default);
    box-shadow: var(--shadow-default);
    border-radius: var(--setting-border-radius-big-default);
    overflow: hidden;
    position: relative;
    text-align: left;
    transition: transform .2s;
}

.client-content {
    height: 90%;
    display: flex;
}

.client-content-half {
    width: 100%;
    text-align: center;
}

.client-content-half > p:nth-of-type(1) {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
}

.client-content-container {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.client-content-divider {
    height: 85%;
    border: 1px solid var(--color-border-bright-default);
    margin-top: 10px;
}

.client-title-container {
    font-size: 1.3em;
    height: 25px;
    border-bottom: 1px solid var(--color-font-header-default);
}

.loading-container {
    top: calc(50% - 40px);
    position: relative;
}

.card-container {
    padding: 0;
}

.card-container:hover:not(.shimmer) >>> * {
    cursor: pointer;
}

.card-container:hover:not(.client-container):not(.undo) {
    background: var(--color-fg-default);
    cursor: default;
}

.card-container:hover:not(.client-container):not(.undo) >>> * {
    cursor: default;
}

.card-container.undo > div > .card-icon {
    margin-top: 50px;
    color: red;
}

.card-container.undo > div > .title-container {
    font-size: 1.3em;
}

.title-container {
    height: 20px;
    line-height: 20px;
    font-size: 1em;
}

.count-container {
    margin-top: 20px;
    height: 40px;
    width: 100%;
    background-color: var(--color-highlight-default);
    color: var(--color-font-highlight-default);
    line-height: 40px;
    font-size: 1.5em;
}

.card-icon {
    margin: 20px 0;
    height: 50px;
}

.card-icon-alt {
    margin: 10px 0;
    height: 70px;
}
</style>