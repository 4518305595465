import Vue from 'vue'
import Router from 'vue-router'

// import HelloWorldPage from './pages/HelloWorldPage'
import LoginPage from './pages/LoginPage'
import DashboardPage from './pages/DashboardPage'
import ClientDataPage from './pages/ClientDataPage'
import NotFoundPage from './pages/NotFoundPage'
import CorporationsPage from './pages/CorporationsPage'
import EditCorporationPage from './pages/EditCorporationPage'
import ContactsPage from './pages/ContactsPage'
import EditContactPage from './pages/EditContactPage'
import ChangeoverDaysPage from './pages/ChangeoverDaysPage'
import EditChangeoverDayPage from './pages/EditChangeoverDayPage'
import AttestationsPage from './pages/AttestationsPage'
import EditAttestationPage from './pages/EditAttestationPage'
import LinksPage from './pages/LinksPage'
import EditLinkPage from './pages/EditLinkPage'
import TextsPage from './pages/TextsPage'
import EditTextPage from './pages/EditTextPage'
import SystemConfigurationPage from './pages/SystemConfigurationPage'
import ProductConfigurationsPage from './pages/ProductConfigurationsPage'
import EditProductConfigurationPage from './pages/EditProductConfigurationPage'
import MailTemplatesPage from './pages/MailTemplatesPage'
import EditMailTemplatePage from './pages/EditMailTemplatePage'

// register navigation
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes: [
        {
            path: '/',
            name: 'root',
            // alway redirect to login first
            // routing then decides wether to show login or another site
            beforeEnter: (to, from, next) => {
                next({
                    path: '/login',
                    query: to.query
                })
            }
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            meta: {
                menu: false,
                menuentry: false,
                display: 'Login'
            },
            beforeEnter: (to, from, next) => {
                // if there is already a session, redirect to dashboard
                if (Vue.getSessionData() !== undefined && !Vue.hasSessionExpired()) {
                    next('/dashboard')
                } else {
                    next()
                }
            }
        },

        // navbar is build in the same order as these entries
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashboardPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.dashboard'
            }
        },
        {
            path: '/clientdata',
            name: 'clientdata',
            component: ClientDataPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.client'
            }
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: ContactsPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.contacts'
            }
        },
        {
            path: '/contacts/edit',
            name: 'editcontact',
            component: EditContactPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/productconfigurations',
            name: 'productconfigurations',
            component: ProductConfigurationsPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.products'
            }
        },
        {
            path: '/productconfigurations/edit',
            name: 'editproductconfiguration',
            component: EditProductConfigurationPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/changeoverdays',
            name: 'changeoverdays',
            component: ChangeoverDaysPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.changeoverdays'
            }
        },
        {
            path: '/changeoverdays/edit',
            name: 'editchangeoverday',
            component: EditChangeoverDayPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/attestations',
            name: 'attestations',
            component: AttestationsPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.attestations'
            }
        },
        {
            path: '/attestations/edit',
            name: 'editattestation',
            component: EditAttestationPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/links',
            name: 'links',
            component: LinksPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.links'
            }
        },
        {
            path: '/links/edit',
            name: 'editlink',
            component: EditLinkPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/texts',
            name: 'texts',
            component: TextsPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.texts'
            }
        },
        {
            path: '/texts/edit',
            name: 'edittext',
            component: EditTextPage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/mailtemplates',
            name: 'mailtemplates',
            component: MailTemplatesPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.mailtemplates'
            }
        },
        {
            path: '/mailtemplates/edit',
            name: 'editmailtemplate',
            component: EditMailTemplatePage,
            meta: {
                menu: true,
                menuentry: false
            }
        },
        {
            path: '/corporations',
            name: 'corporations',
            component: CorporationsPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.corporations'
            }
        },
        {
            path: '/corporations/edit',
            name: 'editcorporation',
            component: EditCorporationPage,
            meta: {
                menu: true,
                menuentry: false
            },
        },
        {
            path: '/systemconfiguration',
            name: 'systemconfiguration',
            component: SystemConfigurationPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'menu.systemconfiguration'
            }
        },
/*         {
            path: '/helloworld',
            name: 'helloworld',
            component: HelloWorldPage,
            meta: {
                menu: true,
                menuentry: true,
                display: 'Hello World'
            }
        }, */
        // ----- DEFAULT ROUTE MUST BE LAST! -----
        {
            path: '*',
            name: 'notfound',
            component: NotFoundPage
        }
    ],
});

// check if the session is still valid
router.beforeEach((to, from, next) => {
    var sessionData = Vue.getSessionData()

    // always allow navigation to root and login
    // without any comment
    if (to.path == '/' || to.path == '/login') {
        next()
        return
    } else if (sessionData === undefined) {
        next('/login')
    } else {
        // check if the session has ended
        // session timestamp is update everytime a call is made -> main.js axios interceptor
        if (Vue.hasSessionExpired()) {
            Vue.clearSessionData()
            Vue.$toast.info(this.$t('general.session-expired'))
            next('/login')
        } else {
            next()
        }
    }
})

export default router;
