<template>
    <div id="page-container" class="has-menu">
        <div v-if="isLoading" class="card-container-wrapper">
            <div v-for="coporation in this.corporationloadinglist" :key="coporation.id" class="card-container shimmer"></div>
        </div>
        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="corporation in this.corporationlist" :key="corporation.id" :class="{ 'shimmer' : isLoading }" @click="goToEdit(corporation)">
                <div class="card-logo-container" >
                    <img v-if="corporation.logo" :src="corporation.logo" class="logo">
                    <v-icon v-else name="image" scale="3" />
                </div>
                <div class="card-title-container">
                    <p class="address">{{ corporation.zip + ' ' + corporation.city }}</p>
                    <p class="designation">{{ corporation.name }}</p>
                </div>
            </div>
            <div class="card-container" @click="newCorporation">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('corporations.newcorporation') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewcorporation" :isloading="isloadingnewcorporation" :isopen="shownewcorporation" @close="hideModal" :title="$t('corporations.newcorporation')" :submitlabel="$t('corporations.buttons.save')" :cancellabel="$t('corporations.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('corporations.name')" :isloading="isloadingnewcorporation" v-model="newcorporation.Name" :error="$v.newcorporation.Name.$error" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingInput from '../components/systems/elements/FloatingInput'

import 'vue-awesome/icons/image'
import 'vue-awesome/icons/plus'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    name: 'CorporationsPage',
    data() {
        return {
            isLoading: true,
            corporationlist: [],
            corporationloadinglist: [{id: 0}, {id: 1}, {id: 2}],
            shownewcorporation: false,
            isloadingnewcorporation: false,
            newcorporation: null
        }
    },
    validations: {
        newcorporation: {
            Name: { required }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.getCorporationsAsync()
        },
        newCorporation: function() {
            this.shownewcorporation = true
            this.newcorporation = {
                Name: ''
            }
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewcorporation = true

                // save new corporation
                // POST '/clients/:clientid/corporations/:corporationid'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/corporations'

                var body = {
                    corporation: this.newcorporation
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingnewcorporation = false
                    } else {
                        this.resetNewCorporation()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            } else {
                this.resetNewCorporation()
            }
        },
        resetNewCorporation: function() {
            this.shownewcorporation = false
            this.newcorporation = null
        },
        getCorporationsAsync: async function() {
            this.isLoading = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + this.sessionData.clientid
            url += '/corporations'
            
            var parent = this

            try {
                var response = await axios.get(url)
            } catch(err) {
                // TODO: implement no data found view
                parent.corporationlist = []
                parent.isLoading = false
                Vue.$toast.error(err)
                return
            }
            
            var responseBody = response.data
            var status = responseBody.status

            this.isLoading = false

            if (status.ErrorCode != 0) {
                this.corporationlist = []
                Vue.$toast.error(status.ErrorMsg)
                return
            }
            
            var resultList = responseBody.data.corporationlist

            this.corporationlist = resultList
            this.$store.commit('newCorporations', resultList)

            Promise.all(resultList.map(async (entry) => {
                var imageResult = await this.loadImage(entry.id)
                entry.logo = imageResult.imgdata
                return entry
            })).then(list => {
                this.corporationlist = list
            })
        },
        loadImage: function(corporationid) {
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + this.sessionData.clientid
            url += '/corporations/' + corporationid
            url += '/logo'

            return axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                var result = ''

                if (status.ErrorCode != 0) {
                    // don't show any error, this is only an overview
                    // if the user navigates to the details, where the logo can be changed
                    // he will get the reason why there is no picture
                    // Vue.$toast.error(status.ErrorMsg)
                } else {
                    result = {
                        imgdata: data.logo,
                        corporationid: corporationid
                    }
                }

                this.isLoadingLogo = false
                return result
            })
            .catch(function(err) {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(corporation) {
            this.$store.commit('newEditCorporation', corporation)
            this.$router.push({ name: 'editcorporation' })
        }
    },
    computed: {
        corporations() {
            return this.$store.state.corporations
        },
        sessionData() {
            return Vue.getSessionData()
        }
    },
    components: {
        Modal,
        FloatingInput
    }
}
</script>

<style scoped>
.designation {
    margin-top: 20px;
}
</style>