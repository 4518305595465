<template>
    <div class="spinner-container">
        <div class="spinner">
            <div class="spinner-inner">
                <v-icon class="spinner-icon" name="user-circle" scale="1"></v-icon>
                <v-icon class="spinner-icon" name="file-alt" scale="1"></v-icon>
                <v-icon class="spinner-icon" name="calendar-check" scale="1"></v-icon>
                <div class="spinner-circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/user-circle'
import 'vue-awesome/icons/file-alt'
import 'vue-awesome/icons/calendar-check'

export default {
    name: 'LoadingSpinner'
}
</script>

<style scoped>
.spinner {
    z-index: 9999;
    transition: all 0.1s linear;
    transform: scale(1);
    z-index: -1;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
}

.spinner-circle {
    animation-play-state: paused;
    border: 5px solid var(--color-font-header-default);
    border-radius: 50%;
    animation: rotate 1s infinite linear;
    border-left-color: var(--color-font-header-40);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.spinner-inner {
    margin: 0 auto;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 5px;
    background-color: var(--color-font-header-10);
}

.spinner-container #spinner div div {
    animation-play-state: running;
}

@keyframes loadicons {
    0% {
        transform: scale(0) translate3d(0, 0, 0);
    }
    11% {
        transform: scale(1.2) translate3d(0, 0, 0);
    }
    22% {
        transform: scale(1) translate3d(0, 0, 0);
    }
    33% {
        transform: scale(0) translate3d(0, 0, 0);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.spinner-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--color-font-header-default);
    font-size: 24px;
    width: 20px;
    height: 20px;
    transform: scale(0.01) translate3d(0, 0, 0);
}

.spinner .spinner-icon:nth-of-type(1) {
    animation: loadicons 3s infinite ease-in-out;
}

.spinner .spinner-icon:nth-of-type(2) {
    animation: loadicons 3s 1s infinite ease-in-out;
}

.spinner .spinner-icon:nth-of-type(3) {
    animation: loadicons 3s 2s infinite ease-in-out;
}
</style>