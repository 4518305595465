<template>
  <div id="page-container" class="has-menu">
      <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="link in this.linkloadinglist" :key="link.id"></div>
        </div>
        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="link in this.linklist" :key="link.ID" @click="goToEdit(link)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="external-link-alt" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ link.Name }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newLink">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('links.newlink') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewlink" :isloading="isloadingnewlink" :isopen="shownewlink" @close="hideModal" :title="$t('links.newlink')" :submitlabel="$t('links.buttons.save')" :cancellabel="$t('links.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('links.name')" :isloading="isloadingnewlink" :error="$v.newlink.Name.$error" v-model="newlink.Name" :isrequired="true"/>
                <div class="spacer"></div>
                <FloatingInput :title="$t('links.link')" :isloading="isloadingnewlink" :error="$v.newlink.Link.$error" v-model="newlink.Link" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
  </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal.vue'
import FloatingInput from '../components/systems/elements/FloatingInput.vue'

import 'vue-awesome/icons/external-link-alt'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloading: false,
            linkloadinglist: [{id:0}, {id: 1}, {id: 2}],
            linklist: [],
            shownewlink: false,
            isloadingnewlink: false,
            newlink: null
        }
    },
    validations: {
        newlink: {
            Name: { required },
            Link: { required }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadLinks()
        },
        newLink: function() {
            this.shownewlink = true
            this.newlink = {
                Name: '',
                Link: ''
            }
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewlink = true

                // save new link
                // POST '/clients/:clientid/links'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/links'

                var body = {
                    link: this.newlink
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingnewlink = false
                    } else {
                        this.resetNewLink()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            } else {
                this.resetNewLink()
            }
        },
        resetNewLink: function() {
            this.shownewlink = false
            this.newlink = null
        },
        loadLinks: function() {
            this.isloading = true

            // GET '/clients/:clientid/links'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/links'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.linklist = data.linklist
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(link) {
            this.$store.commit('newEditLink', link)
            this.$router.push({ name: 'editlink'})
        },
    },
    components: {
        Modal,
        FloatingInput
    }
}
</script>

<style scoped>
</style>