<template>
    <div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToLinks">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('links.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.header }}</label>
                </div>
            </div>

            <DataTitle :title="$t('links.header.details')" />
            <div>
                <div class="input-container">
                    <FloatingInput :title="$t('links.name')" v-model="link.Name" :error="$v.link.Name.$error" :isrequired="true" :isloading="isloading" />
                </div>

                <div class="input-container">
                    <FloatingInput :title="$t('links.link')" v-model="link.Link" :error="$v.link.Link.$error" :isrequired="true" :isloading="isloading" />
                </div>

                <div class="input-container">
                    <FloatingTextarea :title="$t('links.description')" v-model="link.Description" :isloading="isloading" />
                </div>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('links.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle'
import FloatingInput from '../components/systems/elements/FloatingInput'
import FloatingTextarea from '../components/systems/elements/FloatingTextarea'

import Vue from 'vue'
import axios from 'axios'

import getEnv from '@/utils/env.js'

import { required } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.editlink === null) {
                next('/links')
            } else {
                next()
            }
        })
    },
    data() {
        return {
            isloading: false,
            header: ''
        }
    },
    validations: {
        link: {
            Name: { required },
            Link: {
                required,
                withProtocol: (value) => {
                    return value.startsWith('http://') || value.startsWith('https://')
                }
            }
        }
    },
    computed: {
        link() {
            return this.$store.state.editlink
        }
    },
    mounted() {
        if (this.link != null) {
            this.header = this.link.Name
        }
    },
    methods: {
        backToLinks: function() {
            this.$router.push({ name: 'links'})
        },
        saveValues: function() {
            this.$v.$touch()

            if (!this.$v.link.Link.withProtocol) {
                Vue.$toast.error(this.$t('links.wrongformat'))
                return
            }

            if (this.$v.$anyError) {
                Vue.$toast.error(this.$t('links.requiredmissing'))
                return
            }

            this.isloading = true

            // PUT '/clients/:clientid/links/:linkid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/links/' + this.link.ID

            var body = {
                link: this.link,
            }

            axios.put(url, body)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with updatet data, so push the current to the store
                    this.$store.commit('newEditLink', this.link)
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'links'})
            })
        }
    },
    components: {
        DataTitle,
        FloatingInput,
        FloatingTextarea
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}
</style>