<template>
<div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToTemplates">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('mailtemplates.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.template.VorlageType }}</label>
                </div>
            </div>

            <DataTitle :title="$t('mailtemplates.header.details')" />
            <div>
                <div class="input-container">
                    <FloatingInput :title="$t('mailtemplates.regard')" v-model="template.Betreff" :isloading="isloading" />
                </div>

                <div class="input-container">
                    <FloatingTextarea ref="templatetextwrapper" taref="templatetext" :title="$t('mailtemplates.text')" v-model="template.Text" :isloading="isloading"/>
                </div>

                <div v-if="placeholders && placeholders.length > 0" class="input-container">
                    <div v-if="!isloading" class="template-placeholder-header">{{ $t('mailtemplates.available-placeholders')}}</div>
                    <div v-if="!isloading" class="template-placeholder-container">
                        <div class="template-placeholder" @click="addToTextArea(placeholder)" :title="$t('mailtemplates.click-to-insert')" v-for="placeholder in this.placeholders" :key="placeholder">
                            <label class="placeholder-label">{{ placeholder }}</label>
                        </div>
                    </div>
                    <div v-else class="input-line-loading-placeholder shimmer big higher"></div>
                </div>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('mailtemplates.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle.vue'
import FloatingTextarea from '../components/systems/elements/FloatingTextarea.vue'
import FloatingInput from '../components/systems/elements/FloatingInput.vue'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    name: 'EditMailTemplatePage',
    beforeRouteEnter : (to, from, next) => {
        // if there is no mail template to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined yet, we need to use the vm
        next(vm => {
            if (vm.$store.state.editmailtemplate === null) {
                next('/mailtemplates')
            } else {
                next()
            }
        })
    },
    computed: {
        template() {
            return this.$store.state.editmailtemplate
        }
    },
    data() {
        return {
            header: '',
            isloading: false,
            placeholders: null
        }
    },
    mounted() {
        this.getPlaceholders()
    },
    methods: {
        addToTextArea: function(placeholder) {
            let textarea = this.$refs.templatetextwrapper.$refs.templatetext
            let cursorpos = textarea.selectionStart

            this.template.Text = [this.template.Text.slice(0, cursorpos), placeholder, this.template.Text.slice(cursorpos)].join('')
        },
        backToTemplates: function() {
            this.$router.push({ name: 'mailtemplates' })
        },
        getPlaceholders: function() {
            this.isloading = true

            // GET '/basedata/mail/placeholders'

            let url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/mail/placeholders'

            axios.get(url)
            .then(response => {
                this.placeholders = response.data.data.placeholderlist.filter((placeholder) => {
                                            return (placeholder.VorlagenType === this.template.VorlageType) && placeholder.Platzhalter
                                        })
                                        .map((placeholder => {
                                            return placeholder.Platzhalter.string
                                        }))
                                        .flat()

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        saveValues: function() {
            this.isloading = true

            // PUT '/basedata/mail/templates/:templateid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/mail/templates'
            url += '/' + this.template.ID

            var body = {
                template: this.template,
            }

            axios.put(url, body)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with updatet data, so push the current to the store
                    this.$store.commit('newEditMailTemplate', this.template)
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'mailtemplates'})
            })
        }
    },
    components: {
        DataTitle,
        FloatingInput,
        FloatingTextarea,
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: relative;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}

.input-container {
    flex-wrap: wrap;
}

.template-placeholder-header {
    min-width: 100%;
    border-bottom: 1px solid var(--color-highlight-greyscale);
    margin-bottom: 10px;
}

.template-placeholder-container {
    width: 100%;
}

.template-placeholder {
    width: 50%;
    /* margin: 2px 0; */
    display: inline-flex;
}

.template-placeholder > .placeholder-label {
    padding: 5px;
    border-radius: 5px;
}

.template-placeholder > .placeholder-label:hover {
    cursor: pointer;
    background: #00000022;
}

.input-line-loading-placeholder.shimmer.big.higher {
    height: 200px;
}
</style>

<style>
/* editor styles */
.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid var(--color-border-dim-default) !important;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid var(--color-border-dim-default) !important;
    border-top: none !important;
}
</style>