<template>
    <div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToProducts()">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('productconfigurations.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.header }}</label>
                </div>
            </div>

            <div class="data-container" v-if="this.configuration">
                <Accordion :title="$t('productconfigurations.header.product')" :open="true">
                    <!-- Bezeichnung* -->
                    <div class="input-container">
                        <FloatingInput :title="$t('productconfigurations.name')" :error="$v.configuration.ProduktZuordnungBezeichnung.$error" v-model="configuration.ProduktZuordnungBezeichnung" :isrequired="true" :isloading="isloading" />
                    </div>
                    <!-- Produkt* -->
                    <!-- Hintergrundsystem* -->
                    <div class="input-container with-product-id">
                        <FloatingDropdown :title="$t('productconfigurations.product')" @change="productChanged" :error="$v.configuration.ProduktID.$error" :optionlist="productlist" :selected="configuration.ProduktID.toString()" :isrequired="true" v-if="!isloading" />
                        <FloatingInput inputStyle="text-align: right;" :title="$t('productconfigurations.backendid')" :error="$v.configuration.IDHintergrundsystem.$error" v-model="configuration.IDHintergrundsystem" :isrequired="true" v-if="!isloading" />
                        <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                </Accordion>
            </div>


            <div class="data-container" v-if="this.configuration">
                <Accordion :title="$t('productconfigurations.header.tariff')">
                    <!-- Tarifart* -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.tarifftype')" :error="$v.configuration.TarifArt.$error" @change="tariffTypeChanged" :optionlist="tarifftypelist" :selected="configuration.TarifArt" :isrequired="true" :isloading="isloading" />
                    </div>
                    <!-- Bankverbindungsmodus* -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.bankingmode')" @change="paymentModeChanged" :optionlist="bankmodelist" :error="$v.configuration.BankverbindungsModus.$error" :selected="configuration.BankverbindungsModus" :isrequired="true" :isloading="isloading" />
                    </div>
                    <!-- Tarifmodus -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.tariffmode')" @change="tariffModeChanged" :optionlist="tariffmodelist" :selected="configuration.Tarifmodus.toString()" :isloading="isloading" />
                    </div>
                    <!-- Gesamtnetz (+ input, id?) -->
                    <div class="input-container with-product-id">
                        <FloatingDropdown :title="$t('productconfigurations.networkmode')" @change="networkModeChanged" :optionlist="networkmodelist" :selected="configuration.ModusGesamtNetz.toString()" v-if="!isloading" />
                        <FloatingInput :title="$t('productconfigurations.productid')" inputStyle="text-align: right;" v-model="configuration.PreisstufeNetz" :isdisabled="configuration.ModusGesamtNetz === 0" v-if="!isloading" />
                        <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                    <!-- 1. Klasse (+ input, id?) -->
                    <div class="input-container with-product-id">
                        <FloatingDropdown :title="$t('productconfigurations.firstclass')" @change="firstClassPriductChanged" :optionlist="firstclassproductlist" :selected="configuration.ProduktIDFirstClass.toString()" v-if="!isloading" />
                        <FloatingInput :title="$t('productconfigurations.productid')" inputStyle="text-align: right;" v-model="configuration.IDHintergrundsystemFirstClass" :isdisabled="configuration.ProduktIDFirstClass === 0" v-if="!isloading" />
                        <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                </Accordion>
            </div>

            <div class="data-container" v-if="this.configuration">
                <Accordion :title="$t('productconfigurations.header.configuration')">
                    <!-- Abweichender Karteninhaber -->
                    <div v-if="!isloading" class="input-container">
                        <div class="label-container">
                            <label>{{ $t('productconfigurations.differentcardholder') }}</label>
                        </div>
                    </div>
                    <div class="input-container">
                        <ToggleButton v-if="!isloading" :active="configuration.AbweichenderKarteninhaber" @change="differentCardHolderChanged" :label="false" />
                        <div v-else class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                    <!-- Übertragbar -->
                    <div v-if="!isloading" class="input-container">
                        <div class="label-container">
                            <label>{{ $t('productconfigurations.transferable') }}</label>
                        </div>
                    </div>
                    <div class="input-container">
                        <ToggleButton v-if="!isloading" :active="configuration.Transferable" @change="transferableChanged" :label="false" />
                        <div v-else class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                    <!-- Bestellbar ab -->
                    <div class="input-container">
                        <FloatingDate :title="$t('productconfigurations.orderablefrom')" v-model="orderablefrom" :isloading="isloading"/>
                    </div>
                    <!-- Mindestalter -->
                    <div class="input-container">
                        <FloatingInput :title="$t('productconfigurations.minage')" v-model="configuration.Mindestalter" :isloading="isloading" />
                    </div>
                    <!-- Verfahren Ersatzkarte -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.cardreplacement')" @change="cardReplacementChanged" :optionlist="cardreplacementlist" :selected="configuration.VerfahrenErstzkarte.toString()" :isloading="isloading" />
                    </div>
                    <!-- Verfügbarkeit -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.availability')" @change="availabilityChanged" :optionlist="availabilitylist" :selected="configuration.VerfuegbarVariante.toString()" :isloading="isloading" />
                    </div>
                    <!-- Gültigkeitsalgorithmus -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.validity')" @change="validityAlgorithmChanged" :optionlist="validitylist" :selected="configuration.ID_GueltigkeitsAlg.toString()" :isloading="isloading" />
                    </div>
                    <!-- Bild erforderlich -->
                    <div class="input-container">
                        <FloatingDropdown :title="$t('productconfigurations.pictureneeded')" @change="pictureModeChanged" :optionlist="picturerequiredlist" :selected="configuration.PictureActive.toString()" :isloading="isloading" />
                    </div>
                </Accordion>
            </div>

            <div class="data-container" v-if="this.configuration">
                <Accordion title="Misc">
                    <!-- Link zur Umfrage -->
                    <div class="input-container">
                        <FloatingInput :title="$t('productconfigurations.surveylink')" v-model="configuration.OnlineSurveyLink" :isloading="isloading" />
                    </div>
                    <!-- Zusatzinformationen -->
                    <div v-if="!isloading" class="input-container">
                        <div class="label-container">
                            <label>{{ $t('productconfigurations.additionalinformation') }}</label>
                        </div>
                    </div>
                    <div class="input-container">
                        <VueEditor v-if="!isloading" v-model="infotext.text"/>
                    <div v-else class="input-line-loading-placeholder shimmer big"></div>
                </div>
                </Accordion>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('productconfigurations.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import FloatingInput from '../components/systems/elements/FloatingInput.vue'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'
import FloatingDate from '../components/systems/elements/FloatingDate.vue'
import ToggleButton from '../components/systems/elements/ToggleButton.vue'
import Accordion from '../components/systems/Accordion.vue'
import { VueEditor } from 'vue2-editor'

import Vue from 'vue'
import axios from 'axios'

import getEnv from '@/utils/env.js'

import { required } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.editproduct === null) {
                next('/productconfigurations')
            } else {
                next()
            }
        })
    },
    data() {
        return {
            header: '',
            isloading: false,
            productlist: [],
            tarifftypelist: [],
            bankmodelist: [],
            tariffmodelist: [],
            networkmodelist: [],
            firstclassproductlist: [],
            cardreplacementlist: [],
            availabilitylist: [],
            validitylist: [],
            picturerequiredlist: [],
            infotext: '',
            orderablefrom: null
        }
    },
    validations: {
        configuration: {
            ProduktZuordnungBezeichnung: { required },
            ProduktID: {
                required,
                minValue: 0
            },
            IDHintergrundsystem: { required },
            TarifArt: {
                required,
                minValue: 0
            },
            BankverbindungsModus: {
                required,
                minValue: 0
            }
        }
    },
    computed: {
        configuration() {
            return this.$store.state.editproduct
        }
    },
    mounted() {
        if (this.configuration != null) {
            this.getSelectionData()
            this.header = this.configuration.ProduktZuordnungBezeichnung
            var date = new Date(this.configuration.BestellbarAb)

            // delphi null date: 30.12.1899
            if (date.getFullYear() < 1900) {
                this.orderablefrom = null
            } else {
                // https://stackoverflow.com/a/3605248/9368637
                // getMonth() ist starting by 0
                this.orderablefrom = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
            }
        }
    },
    methods: {
        backToProducts: function() {
            this.$router.push({ name: 'productconfigurations' })
        },
        getSelectionData: function() {
            this.isloading = true

            // GET '/clients/:clientid/productconfiguration/:productid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/productconfiguration/' + this.configuration.IDProduktZuordnung

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.productlist = data.productlist.map(entry => {
                        return { displayid: entry.ProduktID, displayname: entry.ProduktBezeichnung }
                    })

                    this.firstclassproductlist = data.firstclassproductlist.map(entry => {
                        return { displayid: entry.ProduktID, displayname: entry.ProduktBezeichnung }
                    })

                    this.tarifftypelist = data.tarifftypelist
                    this.bankmodelist = data.bankmodelist
                    this.tariffmodelist = data.tariffmodelist
                    this.networkmodelist = data.networkoptionlist
                    this.cardreplacementlist = data.replacementoptionlist
                    this.availabilitylist = data.availabilityoptionlist
                    this.validitylist = data.validityoptionlist
                    this.picturerequiredlist = data.picturerequiredoptionlist
                    this.infotext = data.infotext

                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        saveValues: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                Vue.$toast.error(this.$t('productconfigurations.requiredmissing'))
                return
            }

            this.isloading = true

            this.configuration.BestellbarAb = null

            // if set, format orderablefrom to delphi date and set to this.configuration.BestellbarAb
            if (this.orderablefrom) {
                this.configuration.BestellbarAb = new Date(this.orderablefrom).toISOString()
            }

            // PUT '/clients/:clientid/productconfiguration/:productid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/productconfiguration/' + this.configuration.IDProduktZuordnung

            var body = {
                configuration: this.configuration,
                infotext: this.infotext
            }

            axios.put(url, body)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with updatet data, so push the current to the store
                    this.$store.commit('newEditProduct', this.configuration)
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
                this.isloading = false
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'productconfigurations '})
            })
        },
        productChanged: function(event) {
            this.configuration.ProduktID = event.displayid
        },
        tariffTypeChanged: function(event) {
            this.configuration.TarifArt = event.displayid
        },
        paymentModeChanged: function(event) {
            this.configuration.BankverbindungsModus = event.displayid
        },
        tariffModeChanged: function(event) {
            this.configuration.Tarifmodus = event.displayid
        },
        networkModeChanged: function(event) {
            if (event.displayid === 0) {
                this.configuration.PreisstufeNetz = 0
            }
            this.configuration.ModusGesamtNetz = event.displayid
        },
        firstClassPriductChanged: function(event) {
            if (event.displayid === 0) {
                this.configuration.IDHintergrundsystemFirstClass = 0
            }
            this.configuration.ProduktIDFirstClass = event.displayid
        },
        cardReplacementChanged: function(event) {
            this.configuration.VerfahrenErstzkarte = event.displayid
        },
        availabilityChanged: function(event) {
            this.configuration.VerfuegbarVariante = event.displayid
        },
        validityAlgorithmChanged: function(event) {
            this.configuration.ID_GueltigkeitsAlg = event.displayid
        },
        pictureModeChanged: function(event) {
            this.configuration.PictureActive = event.displayid
        },
        differentCardHolderChanged: function(event) {
            // event == toggle state == true/false
            this.configuration.AbweichenderKarteninhaber = event
        },
        transferableChanged: function(event) {
            // event == toggle state == true/false
            this.configuration.Transferable = event
        }
    },
    components: {
        Accordion,
        FloatingInput,
        FloatingDropdown,
        ToggleButton,
        VueEditor,
        FloatingDate
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}

.label-container {
    width: 100%;
    text-align: left;
}

.input-container.with-product-id > div:nth-child(2) {
    margin-left: 10px;
    width: 10%;
    min-width: 90px;
}

.input-container.input-container.with-product-id >>> div {
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>