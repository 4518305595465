<template>
    <div class="container" :class="{ 'no-menu' : hasmenu() }">
        <div v-if="!this.logo">
            <div class="logo-container">
                <div class="img-container">
                    <img class="logo" src="../assets/img/highq_logo.png" @click="toggleTheme"/>
                </div>
            </div>

            <div class="title-container" @click="goHome">
                <div class="title">{{ title }}</div>
                <LoadingSpinner v-if="logoloading" id="spinner"/>
            </div>
        </div>
        <div v-else @click="goHome" class="client-logo-container">
            <img id="logo" :src="this.logo" />
        </div>

        <div class="menu-toggle-container" v-if="showMenu" v-click-outside="hideMenu">
            <v-icon @click="toggleMenu" class="menu-icon" name="bars" scale="2"/>
        </div>

        <div class="actions-narrow" v-if="notLogin">
            <div>
                <v-icon name="sign-out-alt" class="logout" @click="logout"/>
            </div>
            <div>
                <v-icon name="key" class="change-password" @click="changePassword" />
            </div>
        </div>

        <div v-if="showMenu" class="menu-container-wrapper" :class="{ 'menu-toggled' : menuToggled }">
            <div class="menu-container" :class="{ 'menu-toggled' : menuToggled }" id="navbar">
                <div class="item-container" v-for="route in getNavigationList()" :key="route.id" @click="hideMenu()">
                    <router-link :to="route.path" tag="div">{{ $t(route.meta.display) }}</router-link>
                </div>
            </div>

            <div class="actions" v-if="notLogin">
                <div>
                    <v-icon name="sign-out-alt" class="logout" @click="logout"/>
                </div>
                <div>
                    <v-icon name="key" class="change-password" @click="changePassword" />
                </div>
            </div>
        </div>

        <Modal v-if="showchangepassword" :isopen="showchangepassword" :isloading="isloadingchangepassword" @close="hideModal" :title="$t('navbar.changepassword')" :submitlabel="$t('navbar.buttons.confirm')" :cancellabel="$t('navbar.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('navbar.oldpassword')" :isloading="isloadingchangepassword" :error="$v.oldpassword.$error" v-model="oldpassword" :isrequired="true" :password="true" />
                <div class="spacer"></div>
                <FloatingInput :title="$t('navbar.newpassword')" :isloading="isloadingchangepassword" :error="$v.newpassword.$error" v-model="newpassword" :isrequired="true" :password="true" />
                <div class="spacer"></div>
                <FloatingInput :title="$t('navbar.newpasswordconfirm')" :isloading="isloadingchangepassword" :error="$v.newpasswordconfirm.$error" v-model="newpasswordconfirm" :isrequired="true" :password="true" />
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/key'
import 'vue-awesome/icons/sign-out-alt'

import Modal from './systems/elements/Modal.vue'
import FloatingInput from './systems/elements/FloatingInput.vue'
import LoadingSpinner from './systems/elements/Spinner.vue'

import getEnv from '@/utils/env.js'

import ClickOutside from 'vue-click-outside'
import { required, sameAs } from 'vuelidate/lib/validators'

import Vue from 'vue'
import axios from 'axios'

export default {
    name: 'NavBar',
    props: {
        title: {
            type: String,
            default: 'Vuepoc'
        }
    },
    data() {
        return {
            showMenu: this.$router.currentRoute.meta.menu,
            menuToggled: false,
            showchangepassword: false,
            isloadingchangepassword: false,
            oldpassword: null,
            newpassword: null,
            newpasswordconfirm: null,
            logoloading: false
        }
    },
    validations: {
        oldpassword: { required },
        newpassword: { required },
        newpasswordconfirm: { 
            required,
            sameAsPassword: sameAs('newpassword')
        }
    },
    watch: {
        // if $router.place is called, the $router.currentRoute is not "changed" but set
        // so we need to watch for the $route object which is indeed changing
        $route() {
            this.showMenu = this.$router.currentRoute.meta.menu
        },
        // we want to load the logo if we transition from the login into the app
        notLogin(val) {
            if (val) {
                this.getClientLogo()
            }
        }
    },
    methods: {
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingchangepassword = true

                let url = getEnv('VUE_APP_MIDDLEWARE_URL')
                url += '/changepassword/' + Vue.getSessionData().client

                const body = {
                    username: Vue.getSessionData().username,
                    oldpassword: this.oldpassword,
                    newpassword: this.newpassword,
                    newpasswordconfirm: this.newpasswordconfirm
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                    } else {
                        Vue.$toast.info(this.$t('navbar.changepasswordsuccess'))
                        this.showchangepassword = false
                        Vue.clearSessionData()
                        this.$router.push({ name: 'login' })
                    }

                    this.isloadingchangepassword = false
                })
                .catch(err => {
                    Vue.$toast.error(err)
                    this.isloadingchangepassword = false
                })
            } else {
                this.showchangepassword = false
            }
        },
        logout: function() {
            Vue.clearSessionData()
            let login = '/login'
            if (Vue.getDefaultClient()) {
                login += '?client=' + Vue.getDefaultClient()
            }
            this.$router.push(login)
        },
        changePassword: function() {
            this.showchangepassword = true
        },
        goHome: function() {
            this.$router.push({ name: 'root' })
        },
        toggleMenu: function() {
            this.menuToggled = !this.menuToggled
        },
        hideMenu: function() {
            this.menuToggled = false
        },
        hasmenu: function() {
            return !this.$router.currentRoute.meta.menu
        },
        isActiveItem: function(route) {
            var currentRoute = this.$router.currentRoute

            if (route.name === currentRoute.name) {
                return true
            } else {
                return false
            }
        },
        getNavigationList: function() {
            var navItemList = []
            var availableRoutes = this.$router.getRoutes()

            // get all routes with menu meta tag
            for (var iterator=0; iterator < availableRoutes.length; iterator++) {
                var route = availableRoutes[iterator];
                
                if (route.meta.menuentry) {
                    navItemList.push(route)
                }
            }

            return navItemList
        },
        // theme related stuff
        toggleTheme: function() {
            let active = document.querySelector("#alt-theme");
            if (!active) {
                this.addLightTheme()
            } else {
                this.removeLightTheme()
            }
        },
        addLightTheme: function() {
            let themeEl = document.createElement("link")
            themeEl.setAttribute("rel", "stylesheet")
            themeEl.setAttribute("href", "/css/alt-theme.css")
            themeEl.setAttribute("id", "alt-theme")

            let docHead = document.querySelector("html")
            docHead.append(themeEl)
            localStorage["hqtheme"] = JSON.stringify(true)
        },
        removeLightTheme: function() {
            let themeEl = document.querySelector("#alt-theme")
            let parentNode = themeEl.parentNode
            parentNode.removeChild(themeEl)
            localStorage["hqtheme"] = JSON.stringify(false)
        },
        getClientLogo: function() {
            this.logoloading = true

            var sessionData = Vue.getSessionData()

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/logo'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode == 9) {
                    // errCode 9 == no picture set
                    Vue.$toast.warning(status.ErrorMsg)
                } else if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.$store.commit('newClientLogo', data.logo)
                }

                this.logoloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                this.logoloading = false
            })
        }
    },
    mounted() {
        if (localStorage["hqtheme"] && JSON.parse(localStorage["hqtheme"]) && !document.querySelector("#alt-theme")) {
            this.addLightTheme()
        }

        // cover page reload
        if (this.notLogin) {
            this.getClientLogo()
        }
    },
    computed: {
        notLogin: function() {
            return !this.$route.path.includes('login')
        },
        logo: function() {
            if (this.notLogin && this.$store.state.clientlogo) {
                return this.$store.state.clientlogo
            } else {
                return null
            }
        }
    },
    components: {
        Modal,
        FloatingInput,
        LoadingSpinner
    },
    directives: {
        ClickOutside
    }
}
</script>

<style scoped>
#spinner {
    position: absolute;
    right: 5px;
    transform: scale(0.6);
    top: -5px;
}

#logo {
    display: block;
    box-sizing: border-box;
    padding-top: 5px;
    max-height: 60px;
    max-width: 300px;
    margin: auto;
}

.client-logo-container:hover {
    cursor: pointer;
}

.container {
    text-align: left;
    height: fit-content;
    background: var(--color-fg-default);
    border-color: var(--color-border-bright-default);
    z-index: 100;
}

.item-container {
    width: 90%;
    height: 40px;
    margin: 5px 15px;
    line-height: 40px;
    font-size: 1em;
}

.item-container:hover > div:not(.router-link-active) {
    cursor: pointer;
    background-color: var(--color-hover-highlight-10);
    border-radius: var(--setting-border-radius-big-default)
}

.menu-container {
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    background-color: var(--color-fg-default);
    padding-top: 20px;
    box-shadow: var(--shadow-default);
}

.item-container:hover,
.item-container > label:hover {
    cursor: pointer;
}

/* header */
.logo-container {
    margin-left: 50px;
    height: 60px;
    width: fit-content;
    float: left;
    display: table;
}

.img-container {
    display: table-cell;
    vertical-align: middle;
}

.logo-container .logo {
    height: 45px;
}

.title-container {
    float: left;
    margin-left: 25px;
    font-size: 1.2em;
    width: 170px;
    height: 60px;
    display: table;
}

.title-container:hover {
    cursor: pointer;
}

.menu-icon:hover {
    cursor: pointer;
}

.title {
    display: table-cell;
    vertical-align: middle;
}

.actions-narrow {
    visibility: hidden;
    display: flex;
    z-index: 5;
}

.actions {
    position: absolute;
    bottom: 15px;
    left: 5px;
    z-index: 5;
    display: flex;
}

.actions-narrow > div > svg,
.actions > div > svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: var(--setting-border-radius-big-default);
}

.actions-narrow > div > svg:hover,
.actions > div > svg:hover {
    background: var(--color-hover-highlight-10);
    cursor: pointer;
}

.logout {
    color: red;
}

.change-password {
    padding: 6px !important;
    margin-left: 10px;
}

.spacer {
    margin-top: 20px;
}

@media(min-width: 1000px) {
    .container {
        width: 300px;
        height: 60px;
        box-shadow: var(--shadow-default);
        /* border-radius: 0 var(--setting-border-radius-big-default) 0 0; */
        /* margin-top: 25px; */
    }

    .container.no-menu {
        border-radius: 0 0 var(--setting-border-radius-big-default) 0 ;
        /* border-bottom: 1px solid; */
    }

    /* nav height + 100px */
    /* menu is only sticky if the screen is large enough to display it */
    @media(min-height: 600px) { 
        .container {
            position: fixed;
        }
    }

    @media(max-height: 600px) {
        .container {
            position: absolute;
        }
    }

    .menu-toggle-container {
        visibility: hidden;
    }

    .menu-container-wrapper {
        overflow: hidden;
        transition: transform .2s;
        position: absolute;
        top: 60px;
        left: 0;
        height: fit-content;
    }

    .menu-container {
        height: 100%;
        width: 300px;
        padding-bottom: 60px;
        border-radius: 0 0 var(--setting-border-radius-big-default) 0;
    }

    .item-container > div {
        padding-left: 30px;
        text-align: left;
        box-sizing: border-box;
    }

    .router-link-active {
        position: relative;
        box-shadow: var(--shadow-highlight);
        border-radius: var(--setting-border-radius-big-default);
        background-color: var(--color-highlight-default);
        color: var(--color-font-highlight-default)
    }
}

@media(max-width: 1000px) {
    #spinner {
        left: 230px;
        right: unset;
    }
    
    #logo {
        position: absolute;
        left: 10px;
    }
    
    .container {
        width: 100%;
        height: 60px;
        position: fixed;
        box-shadow: var(--shadow-default);
    }

    .menu-toggle-container {
        position: absolute;
        /* make space for the icon which toggles the theme */
        width: calc(100% - 100px);
        text-align: right;
        height: 60px;
        line-height: 60px;
        padding-right: 20px;
        box-sizing: border-box;
        /* make space for the icon which toggles the theme */
        left: 100px;
    }

    .menu-container {
        width: 100%;
        height: fit-content;
        border-radius: 0 0 var(--setting-border-radius-big-default) var(--setting-border-radius-big-default);
        overflow: hidden;
        visibility: hidden;
    }

    .menu-container-wrapper {
        visibility: hidden;
    }

    .item-container {
        text-align: center;
    }

    .menu-container.menu-toggled {
        margin-top: 60px;
        visibility: visible;
        box-shadow: 0px 5px 5px rgb(0 0 0 / 10%);
    }

    .router-link-active {
        margin: 0 auto;
        box-shadow: var(--shadow-highlight);
        border-radius: var(--setting-border-radius-big-default);
        background-color: var(--color-highlight-default);
        color: var(--color-font-highlight-default)
    }

    .actions-narrow {
        position: absolute;
        top: 8px;
        right: 70px;
        left: unset;
        visibility: visible;
    }
}
</style>