<template>
    <div class="component-container" v-if="!isloading">
        <div class="dateinput-container" :style="inputStyle">
            <div class="label-container" :style="labelStyle" v-if="finalname">
                <label>{{ finalname }}</label>
            </div>
            <input v-if="!toggleDisplay" type="date" @input="handleInput($event.target.value)" :value="value" :id="inputid" :required="isrequired" :placeholder="placeholder" :disabled="isdisabled"/>
            <label v-else>{{ value }}</label>
        </div>
    </div>
    <div v-else class="input-line-loading-placeholder shimmer big"></div>
</template>

<script>
export default {
    name: 'FloatingDate',
    props: {
        isrequired: {
            type: Boolean,
            default: false
        },
        isdisabled: {
            type: Boolean,
            default: false
        },
        labelStyle: {
            type: String,
            default: ''
        },
        inputStyle: {
            type: String,
            default: ''
        },
        toggleDisplay: {
            type: Boolean,
            default: false
        },
        isloading: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: ''
        },
        title: String,
        value: String,
        placeholder: String,
        inputid: String
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        }
    },
    computed: {
        finalname() {
            if (this.isrequired) {
                return this.title + '*'
            } else {
                return this.title
            }
        }
    },
}
</script>

<style scoped>
.component-container {
    width: 100%;
    text-align: left;
}

.label-container {
    position: absolute;
    text-align: left;
    font-size: 0.8em;
    left: 10px;
    top: -7.5px;
    padding: 0 5px;
    background: var(--color-fg-default);
    border-radius: 10px;
}

.dateinput-container {
    width: 100%;
    position: relative;
}

input {
    width: 100%;
    padding-top: 5px;
    padding-left: 10px;
}
</style>