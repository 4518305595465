<template>
    <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="changeoverday in this.changeoverdayloadinglist" :key="changeoverday.id"></div>
        </div>
        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="changeoverday in this.changeoverdaylist" :key="changeoverday.IDUmschalttag" @click="goToEdit(changeoverday)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="calendar-day" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ changeoverday.Bezeichnung }}</p>
                    </div>
                </div>
            </div>
            <div class="card-container" @click="newChangeoverDay">
                <div>
                    <div class="card-logo-container new" >
                        <v-icon name="plus" scale="3"/>
                    </div>
                    <div class="card-title-container new">
                        <p>{{ $t('changeoverdays.newday') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewchangeoverday" :isloading="isloadingnewchangeoverday" :isopen="shownewchangeoverday" @close="hideModal" :title="$t('changeoverdays.newday')" :submitlabel="$t('changeoverdays.buttons.save')" :cancellabel="$t('changeoverdays.buttons.abort')">
            <template v-slot:content>
                <FloatingInput :title="$t('changeoverdays.name')" :isloading="isloadingnewchangeoverday" v-model="newchangeoverday.Bezeichnung" :error="$v.newchangeoverday.Bezeichnung.$error" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingInput from '../components/systems/elements/FloatingInput'

import 'vue-awesome/icons/calendar-day'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            isloading: false,
            changeoverdaylist: [],
            changeoverdayloadinglist: [{id: 0}, {id: 1}, {id: 2}],
            shownewchangeoverday: false,
            isloadingnewchangeoverday: false,
            newchangeoverday: null
        }
    },
    validations: {
        newchangeoverday: {
            Bezeichnung: { required }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.getChangeOverDays()
        },
        newChangeoverDay: function() {
            this.shownewchangeoverday = true
            this.newchangeoverday = {
                Bezeichnung: ''
            }
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()
                
                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewchangeoverday = true

                // save new changeoverday
                // POST #/clients/:clientid/changeoverdays'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/changeoverdays'

                var body = {
                    changeoverday: this.newchangeoverday
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.isloadingnewchangeoverday = false
                    } else {
                        this.resetNewChangeoverday()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            } else {
                this.resetNewChangeoverday()
            }
        },
        resetNewChangeoverday: function() {
            this.shownewchangeoverday = false
            this.newchangeoverday = null
        },
        getChangeOverDays: function() {
            this.isloading = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/changeoverdays'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.changeoverdaylist = data.changeoverdaylist
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(changeoverday) {
            this.$store.commit('newEditChangeoverDay', changeoverday)
            this.$router.push({ name: 'editchangeoverday' })
        }
    },
    components: {
        Modal,
        FloatingInput
    }
}
</script>

<style scoped>
</style>