<template>
    <div id="system-container">
        <div v-if="!loading">
            <label for="file-input" class="button file-upload-wrapper">
                <div class="logo-container">
                    <div class="overlay"></div>
                    <v-icon id="logo-placeholder" v-if="!this.logo" name="image" scale="5" />
                    <img id="logo" v-else :src="this.logo" />
                </div>
            </label>
            <input id="file-input" style="display: none" type="file" :accept="accept" value="" @change="uploadImage($event)">
            <div class="upload-hint" v-if="this.hint">
                <label>{{ this.hint }}</label>
            </div>
        </div>

        <div v-if="loading" class="logo-loading-placholder">
            <LoadingSpinner />
        </div>
        
    </div>
</template>

<script>
import 'vue-awesome/icons/image'

import LoadingSpinner from './elements/Spinner.vue'

export default {
    name: 'LogoUpload',
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        logo: {
            type: String,
            default: null
        },
        accept: {
            type: String,
            default: 'image/jpeg,image/bmp'
        },
        hint: String
    },
    data() {
        return {
            
        }
    },
    methods: {
        uploadImage: function(event) {
            this.$emit('upload', event)
        }
    },
    components: {
        LoadingSpinner
    }
}
</script>

<style scoped>
#logo,
#logo-placeholder {
    max-width: 400px;
    height: 75px;
    object-fit: contain;
}

.logo-container {
    position: relative;
    width: fit-content;
    margin: auto;
}

#logo {
    box-shadow: var(--shadow-default)
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: var(--setting-border-radius-big-default);
}

.logo-container:hover .overlay {
    background: black;
    opacity: 0.1;
}

.file-upload-wrapper{
    color: var(--color-font-default);
}

.upload-hint {
    text-align: justify;
    font-size: 0.9em;
    max-width: 300px;
    margin: auto;
    margin-top: 20px;
}
</style>