<template>
<div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToTexts">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('texts.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.header }}</label>
                </div>
            </div>

            <DataTitle :title="$t('texts.header.details')" />
            <div>
                <div class="input-container">
                    <VueEditor v-model="text.Text" v-if="!isloading"/>
                    <div v-else class="input-line-loading-placeholder shimmer big"></div>
                </div>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('texts.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle'
import { VueEditor } from 'vue2-editor'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.edittext === null) {
                next('/texts')
            } else {
                next()
            }
        })
    },
    mounted() {
        if (this.text !== null) {
            this.header = this.text.Name
        }
    },
    computed: {
        text() {
            return this.$store.state.edittext
        }
    },
    data() {
        return {
            header: '',
            isloading: false,
        }
    },
    methods: {
        backToTexts: function() {
            this.$router.push({ name: 'texts' })
        },
        saveValues: function() {
            this.isloading = true

            // PUT '/clients/:clientid/links/:linkid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/texts/' + this.text.IDInfoText

            var body = {
                text: this.text,
            }

            axios.put(url, body)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with updatet data, so push the current to the store
                    this.$store.commit('newEditText', this.text)
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'texts'})
            })
        }
    },
    components: {
        DataTitle,
        VueEditor // TODO: https://github.com/davidroyer/vue2-editor/issues/106
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}

.input-line-loading-placeholder {
    height: 249px;
}
</style>

<style>
/* editor styles */
.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid var(--color-border-dim-default) !important;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid var(--color-border-dim-default) !important;
    border-top: none !important;
}
</style>