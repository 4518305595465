<template>
    <div class="component-container" v-if="!isloading" :class="{ 'error' : error }">
        <div class="dropdown-container" :style="inputStyle">
            <div class="label-container" :style="labelStyle" v-if="finalname">
                <label>{{ finalname }}</label>
            </div>
            <!-- https://stackoverflow.com/a/3518011/9368637 -->
            <select class="input" v-if="!toggleDisplay" :required="isrequired" :placeholder="placeholder" :disabled="isdisabled" @change="handleChange">
                <option :value="defaultitem.displayid" :selected="!selected || selected == ''" v-if="defaultoption" >{{ defaultitem.displayname }}</option>
                <option v-for="option in optionlist" :selected="selected == option.displayid ? 'selected' : ''" :key="option.displayid" :value="option.displayid">{{ option.displayname }}</option>
            </select>
            <label v-else>{{ value }}</label>
        </div>
        
    </div>
    <div v-else class="input-line-loading-placeholder shimmer big"></div>
</template>

<script>
export default {
    props: {
        isrequired: {
            type: Boolean,
            default: false
        },
        isdisabled: {
            type: Boolean,
            default: false
        },
        labelStyle: {
            type: String,
            default: ''
        },
        inputStyle: {
            type: String,
            default: ''
        },
        toggleDisplay: {
            type: Boolean,
            default: false
        },
        isloading: {
            type: Boolean,
            default: false
        },
        defaultoption: {
            type: Boolean,
            default: false
        },
        defaultoptionid: {
            type: [Number, String],
            default: -1
        },
        title: String,
        placeholder: String,
        selected: String,
        optionlist: Array, //of "{displayid: 0, displayname: 'name'}"
        error: Boolean
    },
    methods: {
        handleChange: function(event) {
            // returns the option that was selected
            var optionid = event.target.value
            if (optionid == this.defaultitem.displayid) {
                this.$emit('change', { displayid: this.defaultoptionid, displayname: '' })
            } else {
                var option = this.optionlist.find(entry => entry.displayid == optionid)
                this.$emit('change', option)
            }
        }
    },
    computed: {
        finalname() {
            if (this.isrequired) {
                return this.title + '*'
            } else {
                return this.title
            }
        },
        defaultitem() {
            return { displayid: this.defaultoptionid, displayname: '--- ' + this.$t('general.pleasechoose') + ' ---'}
        }
    },
}
</script>

<style scoped>
.component-container {
    width: 100%;
    text-align: left;
}

.label-container {
    position: absolute;
    text-align: left;
    font-size: 0.8em;
    left: 10px;
    top: -7.5px;
    padding: 0 5px;
    background: var(--color-fg-default);
    border-radius: 10px;
}

.dropdown-container {
    width: 100%;
    position: relative;
}

select {
    width: 100%;
    padding-top: 5px;
    padding-left: 10px;
}

.error >>> * {
    color: red;
    border-color: red;
}
</style>