<template>
    <div id="page-container" class="has-menu">
        <div class="container">
            <div class="header-container">
                <label class="header">{{ $t('systemconfiguration.title') }}</label>
            </div>

            <DataTitle :title="$t('systemconfiguration.header.general')"/>
            <div class="data-container">
                <div class="input-container">
                    <FloatingDropdown :title="$t('systemconfiguration.startpoints')" :isloading="isloading" :optionlist="startpointlist" :selected="systemconfiguration.StartDateCount.toString()" @change="startpointChanged" />
                </div>

                <div class="input-container">
                    <FloatingDropdown :title="$t('systemconfiguration.companydetails')" :isloading="isloading" :optionlist="linklist" :selected="systemconfiguration.IDLinkImpressum.toString()" @change="companyDetailsChanged" />
                </div>

                
                <div class="input-container">
                    <FloatingDropdown :title="$t('systemconfiguration.privacypolicy')" :isloading="isloading" :optionlist="linklist" :selected="systemconfiguration.IDLinkDatenschutz.toString()" @change="privacyPolicyChanged" />
                </div>
            </div>

            <DataTitle :title="$t('systemconfiguration.header.legallinks')"/>
            <div class="data-container">
                <CheckboxList :displaylist="legaloptions" :isloading="isloading" />
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('systemconfiguration.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle.vue'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'
import CheckboxList from '../components/systems/elements/CheckboxList.vue'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    data() {
        return {
            isloading: false,
            systemconfiguration: { StartDateCount: 0, IDLinkImpressum: 0, IDLinkDatenschutz: 0 },
            startpointlist: [],
            linklist: [],
            linklistoriginal: [],
            legallinklist: [],
            legaloptions: []
        }
    },
    mounted() {
        this.getSystemConfiguration()
    },
    methods: {
        getSystemConfiguration: function() {
            this.isloading = true

            // GET '/clients/:clientid/systemconfiguration'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/systemconfiguration'

            axios.get(url)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    // dont stop loading
                    return
                }

                var data = response.data.data

                this.startpointlist = data.startpoints // already comes as { displayid: x, displayname: 'y' }
                
                // this is needed so the legal bindings can easily be saved
                // -> we pass the original linklist and a list of ids that are active
                //    the active links will be assigned as legal binding
                //    the inactive links will be removed
                this.linklistoriginal = data.linklist

                this.linklist = data.linklist.map(entry => {
                        return { displayid: entry.ID, displayname: entry.Name }
                    })

                this.legallinklist = data.legallinklist.map(entry => {
                        return { displayid: entry.ID, displayname: entry.Name }
                    })

                this.systemconfiguration = data.configuration

                this.prepareLegalOptions(data.linklist, data.legallinklist)

                this.isloading = false
            })
        },
        startpointChanged: function(event) {
            this.systemconfiguration.StartDateCount = event.displayid
        },
        companyDetailsChanged: function(event) {
            this.systemconfiguration.IDLinkImpressum = event.displayid
        },
        privacyPolicyChanged: function(event) {
            this.systemconfiguration.IDLinkDatenschutz = event.displayid
        },
        saveValues: function() {
            this.isloading = true

            // PUT '/clients/:clientid/systemconfiguration'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/systemconfiguration'

            var activeidlist = this.legaloptions
                                        .filter(entry => entry.active)
                                        .map(entry => entry.displayid)

            var body = {
                configuration: this.systemconfiguration,
                linklist: this.linklistoriginal,
                activelinkids: activeidlist
            }

            axios.put(url, body)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    this.isloading = false
                } else {
                    // reinit the page
                    this.getSystemConfiguration()
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        prepareLegalOptions: function(alllinks, activelinks) {
            var result = []
            var activeidlist = activelinks.map(entry => entry.ID)

            for (var entry in alllinks) {
                var link = alllinks[entry]
                
                result.push({
                    displayid: link.ID,
                    displayname: link.Name,
                    active: activeidlist.includes(link.ID)
                })
            }

            // order: selected items first
            result.sort((first, second) => {
                if (first.active && !second.active) {
                    return -1
                }

                if (!first.active && second.active) {
                    return 1
                }

                return 0
            })

            this.legaloptions = result
        }
    },
    components: {
        DataTitle,
        FloatingDropdown,
        CheckboxList
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.header-container {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.2em;
    color: var(--color-font-header-default);
    height: 21px;
}

.data-container {
    margin-left: 5px;
    margin-top: 15px;
}
</style>