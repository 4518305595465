<template>
    <div id="page-container" class="has-menu">
        <form class="container">
            <div class="header-container">
                <label class="header">{{ this.header }}</label>
            </div>
            <div class="logo-container">
                <DataTitle :title="$t('client.header.logo')" />
                <div class="data-container">
                    <div class="logo-data-container input-container" >
                        <LogoUpload :loading="this.isLoadingLogo" :logo="this.logoinput" @upload="uploadImage" :hint="$t('client.uploadhint')" />
                    </div>
                </div>
            </div>
            <div class="client-container">
                <DataTitle :title="$t('client.header.information')" />
                <div class="data-container">
                    <div class="input-container two-elements">
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.name.$error" :isrequired="true" :title="$t('client.name')"  v-model="clientdatainput.name" :toggleDisplay="editMode" />
                        <FloatingInput v-if="!isLoadingData" :title="$t('client.abbreviation')" :isdisabled="true" v-model="clientdatainput.abbreviation" class="abbreviation-input" :toggleDisplay="editMode"/>
                        <div v-if="isLoadingData" class="input-line-loading-placeholder shimmer big"></div>
                    </div>

                    <div class="input-container">
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.creditorid.$error" :isrequired="true" :title="$t('client.creditorid')"  v-model="clientdatainput.creditorid" :toggleDisplay="editMode"/>
                        <div v-if="isLoadingData" class="input-line-loading-placeholder shimmer big"></div>
                    </div>

                    <div class="input-container two-elements">
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.zip.$error" :isrequired="true" :title="$t('client.zip')" v-model="clientdatainput.zip" class="zip-input" :toggleDisplay="editMode"/>
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.city.$error" :isrequired="true" :title="$t('client.city')"  v-model="clientdatainput.city" :toggleDisplay="editMode" />
                        <div v-if="isLoadingData" class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                    <div class="input-container two-elements">
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.street.$error" :isrequired="true" :title="$t('client.street')" v-model="clientdatainput.street" :toggleDisplay="editMode"/>
                        <FloatingInput v-if="!isLoadingData" :error="$v.clientdatainput.housenr.$error" :isrequired="true" :title="$t('client.housenr')" v-model="clientdatainput.housenr" class="house-nr-input" :toggleDisplay="editMode"/>
                        <div v-if="isLoadingData" class="input-line-loading-placeholder shimmer big"></div>
                    </div>
                </div>
            </div>
            <div class="contact-container">
                <DataTitle :title="$t('client.header.contact')" />
                <div class="data-container">
                    <div class="input-container">
                        <FloatingInput :isloading="isLoadingData" :error="$v.clientdatainput.email.$error" :isrequired="true" :title="$t('client.email')" v-model="clientdatainput.email" :toggleDisplay="editMode"/>
                    </div>
                    <div class="input-container">
                        <FloatingInput :isloading="isLoadingData" :error="$v.clientdatainput.phone.$error" :isrequired="true" :title="$t('client.phone')" v-model="clientdatainput.phone" :toggleDisplay="editMode"/>
                    </div>
                    <div class="input-container">
                        <FloatingInput :isloading="isLoadingData" :title="$t('client.fax')" v-model="clientdatainput.fax" :toggleDisplay="editMode"/>
                    </div>
                </div>
            </div>

            <div class="button-container add-space-top">
                <input :disabled="isLoadingData || isLoadingLogo" type="button" class="button" :value="$t('client.buttons.save')" @click="saveValues" />
            </div>
        </form>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import 'vue-awesome/icons/image'

import getEnv from '@/utils/env.js'

import FloatingInput from '../components/systems/elements/FloatingInput.vue'
import DataTitle from '../components/systems/elements/DataTitle.vue'

import LogoUpload from '../components/systems/LogoUpload.vue'

import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ClientDataPage',
    data() {
        return {
            isLoadingData: true,
            isLoadingLogo: true,
            clientdatainput: {
                name: '',
                creditorid: '',
                abbreviation: '',
                zip: '',
                city: '',
                street: '',
                housenr: '',
                email: '',
                phone: '',
                fax: '',
            },
            logoinput: '',
            editMode: false
        }
    },
    validations: {
        clientdatainput: {
            name: { required },
            creditorid: { required },
            zip: { required },
            city: { required },
            street: { required },
            housenr: { required },
            email: { required },
            phone: { required }
        }
    },
    mounted() {
        this.getClientData()
        this.getClientLogo()
    },
    computed: {
        clientdata() {
            return this.$store.state.clientdata
        },
        clientlogo() {
            return this.$store.state.clientlogo
        },
        header() {
            if (this.clientdata) {
                return this.clientdata.name + ' - ' + this.clientdata.abbreviation
            } else {
                return ''
            }
        }
    },
    methods: {
        saveValues: function() {
            // the saving happens async
            this.$v.$touch()
            
            if (this.$v.$anyError) {
                this.$toast.error(this.$t('client.requiredmissing'))
                return
            } else {
                this.saveLogo();
                this.saveData();
            }
        },
        saveData: function() {
            this.isLoadingData = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/data'

            var postbody = {
                    name: this.clientdatainput.name,
                    abbreviation: this.clientdatainput.abbreviation,
                    creditorid: this.clientdatainput.creditorid,
                    zip: this.clientdatainput.zip,
                    city: this.clientdatainput.city,
                    street: this.clientdatainput.street,
                    housenr: this.clientdatainput.housenr,
                    email: this.clientdatainput.email,
                    phone: this.clientdatainput.phone,
                    fax: this.clientdatainput.fax
                }

            axios.post(url, postbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.$store.commit('newClientData', this.clientdatainput)
                }

                this.isLoadingData = false
            })
            .catch(err => {
                // TODO: implement no data found view
                this.isLoadingData = false
                Vue.$toast.error(err)
            })
        },
        saveLogo: function() {
            this.isLoadingLogo = true

            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/logo'

            var postbody = {
                    imgdata: this.logoinput
                }

            axios.post(url, postbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    this.logoinput = ''
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.$store.commit('newClientLogo', this.logoinput)
                }

                this.isLoadingLogo = false
            })
            .catch(err => {
                this.isLoadingLogo = false
                this.logoinput = ''
                Vue.$toast.error(err)
            })
        },
        uploadImage: function(event) {
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.onload = loadEvent => {
                    this.logoinput = loadEvent.target.result;
                }

            reader.readAsDataURL(image)
        },
        mapData: function(serviceData) {
            this.clientdatainput.name = serviceData.name
            this.clientdatainput.abbreviation = serviceData.abbreviation
            this.clientdatainput.creditorid = serviceData.creditorid
            this.clientdatainput.zip = serviceData.zip
            this.clientdatainput.city = serviceData.city
            this.clientdatainput.street = serviceData.street
            this.clientdatainput.housenr = serviceData.housenr.toString()
            this.clientdatainput.email = serviceData.email
            this.clientdatainput.phone = serviceData.phone
            this.clientdatainput.fax = serviceData.fax
        },
        getClientData: function() {
            this.isLoadingData = true
            
            var sessionData = Vue.getSessionData()
            
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/data'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // map stored data to inputs
                    this.mapData(data)
                    this.$store.commit('newClientData', data)
                }

                this.isLoadingData = false
            })
            .catch(err => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
            })
        },
        getClientLogo: function() {
            this.isLoadingLogo = true
            
            var sessionData = Vue.getSessionData()
            
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/logo'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode == 9) {
                    // errCode 9 == no picture set
                    Vue.$toast.warning(status.ErrorMsg)
                    this.logoinput = ''
                } else if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    this.logoinput = ''
                } else {
                    this.logoinput = data.logo
                    this.$store.commit('newClientLogo', data.logo)
                }

                this.isLoadingLogo = false
            })
            .catch(err => {
                // TODO: implement no data found view
                Vue.$toast.error(err)
                this.isLoadinLogo = false
                this.logoinput = ''
            })
        }
    },
    components: {
        LogoUpload,
        FloatingInput,
        DataTitle
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.add-space-top {
    margin-top: 50px;
}

.data-container {
    margin-left: 5px;
    margin-top: 15px;
}

.split-content-container {
    display: flex;
    text-align: left;
}

.label-container {
    width: 20%;
}

.input-container.two-elements > div:nth-child(2) {
    margin-left: 10px;
}

.house-nr-input {
    width: 150px;
}

.abbreviation-input {
    width: 150px;
}

.zip-input {
    width: 150px;
}

.logo-data-container {
    text-align: left;
    display: flex;
    align-items: center;
}

#logo,
#logo-placeholder {
    max-width: 400px;
    height: 75px;
    object-fit: contain;
}

.logo-loading-placholder {
    width: 100%;
    height: 75px;
}

.button-container {
    text-align: left;
    width: calc(90% - 10px);
    margin-left: auto;
    margin-right: auto;
}

.header-container {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.2em;
    color: var(--color-font-header-default);
    height: 21px;
}
</style>