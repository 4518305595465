<template>
    <div id="page-container" class="has-menu">
        <div class="container">
            <div class="nav-back-container" @click="backToChangeoverDays()">
                <v-icon class="back-button" name="caret-left" scale="2" />
                <label>{{ $t('changeoverdays.buttons.goback') }}</label>
            </div>
            <div class="title-container">
                <div class="title-label-container">
                    <label>{{ this.contactheader }}</label>
                </div>
            </div>

            <DataTitle :title="$t('changeoverdays.header.details')" />
            <div class="data-container" v-if="changeoverday != null">
                <div class="input-container">
                    <FloatingInput :title="$t('changeoverdays.name')" v-model="changeoverday.Bezeichnung" :error="$v.changeoverday.Bezeichnung.$error" :isloading="isloading" :isrequired="true"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown class="input-row" :title="$t('changeoverdays.productconfiguration')" :isloading="isloading" :optionlist="productlist" :selected="changeoverday.Umschaltart.toString()" @change="productChanged"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown :title="$t('changeoverdays.variant')" :isloading="isloading" :optionlist="variantlist" :selected="changeoverday.Umschaltvariante.toString()" @change="variantChanged"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown :title="$t('changeoverdays.day')" :isloading="isloading" :optionlist="possibledaylist" :selected="changeoverday.Umschalttag.toString()" @change="dayChanged"/>
                </div>

                <div class="input-container">
                    <FloatingDropdown :title="$t('changeoverdays.type')" :isloading="isloading" :optionlist="typelist" :selected="changeoverday.Umschaltart.toString()" @change="typeChanged"/>
                </div>

                <div class="input-container">
                    <FloatingTextarea :title="$t('changeoverdays.additionalinformation')" v-model="infotext.text" :isloading="isloading" />
                </div>
            </div>

            <div class="button-container">
                <input :disabled="isloading" type="button" class="button" :value="$t('changeoverdays.buttons.save')" @click="saveValues" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTitle from '../components/systems/elements/DataTitle'
import FloatingInput from '../components/systems/elements/FloatingInput'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'
import FloatingTextarea from '../components/systems/elements/FloatingTextarea'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

import { required } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter : (to, from, next) => {
        // if there is no corpo to edit redirect to the overview
        // in beforeRouteEnter "this" is not defined, we need to use the vm
        next(vm => {
            if (vm.$store.state.editchangeoverday === null) {
                next('/changeoverdays')
            } else {
                next()
            }
        })
    },
    data() {
        return {
            contactheader: '',
            isloading: false,
            productlist: [],
            variantlist: [],
            possibledaylist: [],
            typelist: [],
            infotext: ''
        }
    },
    validations: {
        changeoverday: { 
            Bezeichnung: { required }
        }
    },
    computed: {
        changeoverday() {
            return this.$store.state.editchangeoverday
        }
    },
    mounted() {
        if (this.changeoverday != null) {
            this.contactheader = this.changeoverday.Bezeichnung
            this.getSelectionData()
        }
    },
    methods: {
        backToChangeoverDays: function() {
            this.$router.push({ name: 'changeoverdays'})
        },
        getSelectionData: function() {
            this.isloading = true

            // GET '/clients/:clientid/changeoverdays/:changeoverdayid/products/:productid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/changeoverdays/' + this.changeoverday.IDUmschalttag
            url += '/products/' + this.changeoverday.IDProduktZuordnung

            axios.get(url)
            .then(response => {
                var responsebody = response.data
                var status = responsebody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    this.backToChangeoverDays()
                } else {
                    var data = responsebody.data

                    this.productlist = data.productlist.map(entry => {
                        return { displayid: entry.IDProduktZuordnung, displayname: entry.ProduktZuordnungBezeichnung }
                    })

                    this.variantlist = data.variantlist.map(entry => {
                        return { displayid: entry.displayid, displayname: entry.displayname }
                    })

                    this.possibledaylist = data.possibledaylist.map(entry => {
                        return { displayid: entry.displayid, displayname: entry.displayname }
                    })

                    this.typelist = data.typelist.map(entry => {
                        return { displayid: entry.displayid, displayname: entry.displayname }
                    })

                    this.infotext = data.infotext

                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
                this.backToChangeoverDays()
            })
        },
        productChanged: function(option) {
            this.changeoverday.IDProduktZuordnung = option.displayid
        },
        variantChanged: function(option) {
            this.changeoverday.Umschaltvariante = option.displayid
        },
        dayChanged: function(option) {
            this.changeoverday.Umschalttag = option.displayid
        },
        typeChanged: function(option) {
            this.changeoverday.Umschaltart = option.displayid
        },
        saveValues: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                Vue.$toast.error(this.$t('changeoverdays.requiredmissing'))
                return
            }

            this.isloading = true

            // PUT '/clients/:clientid/changeoverdays/:changeoverdayid/products/:productid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/changeoverdays/' + this.changeoverday.IDUmschalttag
            url += '/products/' + this.changeoverday.IDProduktZuordnung

            var body = {
                changeoverday: this.changeoverday,
                infotext: this.infotext
            }

            axios.put(url, body)
            .then(response => {
                var status = response.data.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with updatet data, so push the current to the store
                    this.$store.commit('newEditChangeoverDay', this.changeoverday)
                }

                this.isloading = false
            })
            .catch(err => {
                Vue.$toast.error(err)
                    
                    // don't navigate?
                    // the data is still valid if the user tries to save again,
                    // if the user wants the original data, he has to navigate back and forth though...

                // navigate back so the data is kept up to date
                this.$router.push({ name: 'changeoverdays '})
            })
        }
    },
    components: {
        FloatingInput,
        DataTitle,
        FloatingDropdown,
        FloatingTextarea
    }
}
</script>

<style scoped>
@media(min-width: 1001px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    box-shadow: var(--shadow-default);
    background-color: var(--color-fg-default);
    min-width: 650px;
    width: 90%;
    height: fit-content;
    border-radius: var(--setting-border-radius-big-default);
    padding: 15px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.title-container {
    margin-bottom: 30px;
    position: realtive;
}

.title-label-container {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.data-container {
    margin-top: 30px;
}
</style>