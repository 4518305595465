<template>
  <div>
        <DataTitle :title="$t('corporations.header.fields')" />
        <div class="config-list-container">
            <div class="list-wrapper" v-if="isloading">
                <div class="config-list" v-for="config in loadinglist" :key="config.FeldID">
                    <div class="config-container loading shimmer big">
                    </div>
                </div>
            </div>
            <div class="list-wrapper" v-else>
                <div class="config-list" v-for="(config, index) in configurations" :key="config.FeldID">
                    <div class="config-container">
                        <div class="config-title">
                            <span>{{ config.FeldName }}</span>
                        </div>
                        <table>
                            <tr class="input-row">
                                <td class="config-label">
                                    <label :for="index + 'isvisible'" >{{ $t('corporations.showfield') }}</label>
                                </td>
                                <td class="config-input">
                                    <input type="checkbox" :id="index + 'isvisible'" v-model="config.Anzeigen">
                                </td>
                            </tr>
                            <tr class="input-row">
                                <td class="config-label">
                                    <label :for="index + '-isrequired'" >{{ $t('corporations.requirefield') }}</label>
                                </td>
                                <td class="config-input">
                                    <input type="checkbox" :id="index + '-isrequired'" v-model="config.Pflichtfeld">
                                </td>
                            </tr>
                            <tr class="input-row">
                                <td class="config-label">
                                    <label :for="index + '-fieldname'" >{{ $t('corporations.displaytext') }}</label>
                                </td>
                                <td class="config-input">
                                    <input type="text" :id="index + '-fieldname'" v-model="config.externeBezeichnung">
                                </td>
                            </tr>
                            <tr class="input-row">
                                <td class="config-label">
                                    <label :for="index + '-addtionaltext'" >{{ $t('corporations.additionaltext') }}</label>
                                </td>
                                <td class="config-input">
                                    <input type="text" :id="index + '-addtionaltext'" v-model="config.Zusatztext">
                                </td>
                            </tr>
                            <tr class="input-row">
                                <td class="config-label align-top">
                                    <label>{{ $t('corporations.order') }}</label>
                                </td>
                                <td class="config-input">
                                    <div class="radio-container">
                                        <input type="radio" :id="index + '-first'" value="4" :name="config.FeldName" v-model="config.Sortierreihenfolge" @change="handleOrderSelection($event, index, config)">
                                        <label class="radio-label" :for="index + '-first'" >{{ $t('corporations.voucher') }}</label>
                                    </div>
                                    <div class="radio-container">
                                        <input type="radio" :id="index + '-second'" value="1" :name="config.FeldName" v-model="config.Sortierreihenfolge" @change="handleOrderSelection($event, index, config)">
                                        <label class="radio-label" :for="index + '-second'" >{{ $t('corporations.position1') }}</label>
                                    </div>
                                    <div class="radio-container">
                                        <input type="radio" :id="index + '-third'" value="2" :name="config.FeldName" v-model="config.Sortierreihenfolge" @change="handleOrderSelection($event, index, config)">
                                        <label class="radio-label" :for="index + '-third'" >{{ $t('corporations.position2') }}</label>
                                    </div>
                                    <div class="radio-container">
                                        <input type="radio" :id="index + '-fourth'" value="3" :name="config.FeldName" v-model="config.Sortierreihenfolge" @change="handleOrderSelection($event, index, config)">
                                        <label class="radio-label" :for="index + '-fourth'" >{{ $t('corporations.position3') }}</label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-container">
            <input :disabled="isloading" type="button" class="button" :value="$t('corporations.buttons.save')" @click="saveConfiguration" />
        </div>
  </div>
</template>

<script>
import DataTitle from './systems/elements/DataTitle'

import getEnv from '@/utils/env.js'

import Vue from 'vue'
import axios from 'axios'

export default {
    data() {
        return {
            isloading: false,
            loadinglist: [{FeldID: 0}, {FeldID: 1}],
            configurations: []
        }
    },
    computed: {
        fieldconfigs() {
            var configList = []
            configList = JSON.parse(this.$store.state.editcorporation.fieldconfig)
            return configList
        },
        corporation() {
            return this.$store.state.editcorporation
        }
    },
    mounted() {
        this.configurations = this.fieldconfigs
    },
    methods: {
        loadConfiguration: function() {
            // TODO: currently the configs are only avaible in the edit corporation...
        },
        saveConfiguration: function() {
            this.isloading = true

            var alreadyused = []

            for (var entry in this.configurations) {
                var config = this.configurations[entry]
                var order = parseInt(config.Sortierreihenfolge)

                if (order != 4) {
                    if (alreadyused.includes(order)) {
                        Vue.$toast.error(this.$t('corporations.invalid-field-order'))
                        this.isloading = false
                        return
                    } else {
                        alreadyused.push(order)
                    }
                }
            }

            var sessionData = Vue.getSessionData()
            
            // /clients/:clientid/corporations/:corporationid'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + sessionData.clientid
            url += '/corporations/' + this.corporation.id

            // we have to stringify the config and append it to the currently selected corporation
            // since there is no service that lets us update the config only
            var configString = JSON.stringify(this.configurations)
            // repair config
            // configString = '[{"FeldID":1,"FeldName":"Personalnummer","Anzeigen":true,"Pflichtfeld":true,"MaxZeichen":10,"externeBezeichnung":"Personalnummer","Zusatztext":"Hello","Sortierreihenfolge":1},{"FeldID":2,"FeldName":"Abteilungsnummer","Anzeigen":false,"Pflichtfeld":false,"MaxZeichen":10,"externeBezeichnung":"Abteilungsnummer","Zusatztext":"World","Sortierreihenfolge":1},{"FeldID":3,"FeldName":"FreieReferenz","Anzeigen":false,"Pflichtfeld":false,"MaxZeichen":20,"externeBezeichnung":"Freie Referenz","Zusatztext":"Test","Sortierreihenfolge":3},{"FeldID":4,"FeldName":"Aktionscode","Anzeigen":false,"Pflichtfeld":false,"MaxZeichen":30,"externeBezeichnung":"Aktionscode reduzierter Preis","Zusatztext":"Case","Sortierreihenfolge":4}]'
            this.corporation.fieldconfig = configString

            var putbody = {
                corporation: this.corporation
            }

            axios.put(url, putbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // service does not answer with the saved corpo
                    //       so we have to push the current corporation back into the editCorporation
                    this.$store.commit('newEditCorporation', this.corporation)
                }

                this.isloading = false
            })
            .catch(function(err) {
                this.isloading = false
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'corporations'})
            })
        },
        handleOrderSelection: function(event, index, currentconfig) {
            // multiple items can have Sortierreihenfolge 4 (voucher)
            // for the other 3 we have to check if there is overlap
            var neworder = parseInt(event.target.value)
            var checkorder = neworder != 4

            if (checkorder) {
                for (var entry in this.configurations) {
                    var config = this.configurations[entry]
                    if (config.FeldID != currentconfig.FeldID
                        && config.Sortierreihenfolge == neworder) {
                        // abort if one config already has neworder id
                        Vue.$toast.warning(this.$t('corporations.field-position-already-in-use'))
                        // reset the order, so the UI represents the correct state
                        this.configurations[index].Sortierreihenfolge = currentconfig.Sortierreihenfolge
                        return false
                    }
                }
            }
        }
    },
    components: {
        DataTitle
    }
}
</script>

<style>
.list-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 500px;
}

.config-container {
    margin: 10px;
    box-shadow: var(--shadow-default);
    border-radius: var(--setting-border-radius-big-default);
    padding: 25px;
    text-align: left;
    width: fit-content;
}

.config-container.loading {
    width: 281px;
    height: 350px;
}

.config-title {
    font-weight: bold;
    border-bottom: 1px solid var(--color-font-header-default);
    color: var(--color-font-header-default);
    font-size: 1.2em;
    margin-bottom: 10px;
}

.config-label {
    padding: 10px 0;
    padding-right: 20px;
}

.config-input {
    margin-left: 10px;
}

.align-top {
    vertical-align: top;
}

.radio-container {
    margin: 5px 0;
}

input[type="radio"] {
    display: inline-block;
    vertical-align: top;
    height: unset;
}

.button-container {
    margin-top: 50px;
    text-align: left;
    width: calc(90% - 10px);
    margin-left: auto;
    margin-right: auto;
}

input[type="text"] {
    width: 100%;
}
</style>