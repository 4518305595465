<template>
    <div>
        <DataTitle :title="$t('corporations.header.products')" />

        <div class="content-container">
            <CheckboxList :displaylist="productlist" :isloading="isloading"/>
        </div>

        <div class="button-container">
            <input :disabled="isloading" type="button" class="button" :value="$t('corporations.buttons.save')" @click="saveConfiguration" />
        </div>
    </div>
</template>

<script>
import DataTitle from './systems/elements/DataTitle'
import CheckboxList from './systems/elements/CheckboxList'

import getEnv from '@/utils/env.js'

import axios from 'axios'
import Vue from 'vue'

export default {
    name: 'ProductConfiguration',
    data() {
        return {
            productlist: [],
            isloading: false
        }
    },
    mounted() {
        this.getProductConfiguration()
    },
    computed: {
        corporation() {
            return this.$store.state.editcorporation
        },
        sessionData() {
            return Vue.getSessionData()
        }
    },
    methods: {
        getProductConfiguration: function() {
            this.isloading = true
            
            // GET '/clients/:clientid/corporations/:corporationid/logo'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + this.sessionData.clientid
            url += '/corporations/' + this.corporation.id
            url += '/productconfiguration'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.prepareProductList(data.availableproducts, data.configuredproducts)
                }

                this.isloading = false
            })
            .catch(function(err) {
                Vue.$toast.error(err)
                this.isloading= false
            })
        },
        prepareProductList: function(available, configured) {
            this.productlist = []
            
            if (available == null || available.length === 0) {
                return
            }

            for (var entry in available) {
                var product = available[entry]
                // add a status to the product
                // if the available product is also in the configured list, set active to true
                product.active = false

                for (var configuredEntry in configured) {
                    var configuredProduct = configured[configuredEntry]
                    if (configuredProduct.IDProductconfiguration == product.IDProductconfiguration) {
                        product.active = true
                    }
                }

                // map to the checkboxlist format
                this.productlist.push({displayid: product.IDProductconfiguration, displayname: product.Bezeichnung, active: product.active})
            }
        },
        saveConfiguration: function() {
            this.isloading = true

            // PUT '/clients/:clientid/corporations/:corporationid/productconfiguration'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + this.sessionData.clientid
            url += '/corporations/' + this.corporation.id
            url += '/productconfiguration'

            var putbody =
                {
                    productlist: this.productlist
                }

            axios.put(url, putbody)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status

                // stop loading before the refresh so the UI
                // keeps the correct state
                this.isloading = false

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    // on success reload the list
                    this.getProductConfiguration()
                }
            })
            .catch(function(err) {
                this.isloading = false
                Vue.$toast.error(err)
                // navigate back so the data is kept up to date
                this.$router.push({ name: 'corporations'})
            })
        }
    },
    components: {
        DataTitle,
        CheckboxList
    },
}
</script>

<style scoped>
.button-container {
    margin-top: 50px;
    text-align: left;
    width: calc(90% - 10px);
    margin-left: auto;
    margin-right: auto;
}
</style>