<template>
    <div id="page-container" class="has-menu">
        <div v-if="isloading" class="card-container-wrapper">
            <div class="card-container shimmer" v-for="text in this.textloadinglist" :key="text.id"></div>
        </div>

        <div v-else class="card-container-wrapper">
            <div class="card-container" v-for="text in this.textlist" :key="text.IDInfoText" @click="goToEdit(text)">
                <div>
                    <div class="card-logo-container" >
                        <v-icon name="font" scale="3"/>
                    </div>
                    <div class="card-title-container">
                        <p class="designation">{{ text.Name.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shownewtext" :isloading="isloadingnewtext" :isopen="shownewtext" @close="hideModal" :title="$t('texts.newtext')" :submitlabel="$t('texts.buttons.save')" :cancellabel="$t('texts.buttons.abort')">
            <template v-slot:content>
                <FloatingDropdown :title="$t('texts.texttype')" :isloading="isloadingnewtext" :optionlist="texttypeoptions" :selected="newtext.Variante.toString()" @change="typeChange" :isrequired="true"/>
                <div class="spacer"></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/systems/elements/Modal'
import FloatingDropdown from '../components/systems/elements/FloatingDropdown.vue'

import getEnv from '@/utils/env.js'

import 'vue-awesome/icons/font'

import Vue from 'vue'
import axios from 'axios'

export default {
    data() {
        return {
            isloading: false,
            textloadinglist: [{id: 0}, {id: 1}, {id: 2}],
            textlist: [],
            shownewtext: false,
            isloadingnewtext: false,
            texttypeoptions: [],
            newtext: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function() {
            this.loadTexts()
        },
        typeChange: function(event) {
            this.newtext.Variante = event.displayid
            this.newtext.Name = event.displayname
        },
        hideModal: function(submit) {
            if (submit) {
                this.$v.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.isloadingnewtext = true

                // save new text
                // POST '/clients/:clientid/texts'
                var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
                url += '/' + Vue.getSessionData().clientid
                url += '/texts/'

                var body = {
                    text: this.newtext
                }

                axios.post(url, body)
                .then(response => {
                    var status = response.data.status

                    if (status.ErrorCode !== 0) {
                        Vue.$toast.error(status.ErrorMsg)
                        this.this.isloadingnewtext = false
                    } else {
                        this.resetNewText()
                        this.init()
                    }
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })

            } else {
                this.resetNewText()
            }
        },
        resetNewText: function() {
            this.shownewtext = false
            this.newtext = null
            this.texttypeoptions = []
            this.isloadingnewtext = false
        },
        newText: function() {
            this.shownewtext = true,
            this.newtext = {
                Name: '',
                Variante: -1,
            }

            this.isloadingnewtext = true

            // GET '/basedata/texttypes'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL')
            url += '/basedata/texttypes'

            axios.get(url)
            .then(response => {
                var responseBody = response.data
                var status = responseBody.status
                var data = responseBody.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.texttypeoptions = data.texttypeoptions
                    this.newtext.Variante = this.texttypeoptions[0].displayid
                    this.newtext.Name = this.texttypeoptions[0].displayname

                    this.isloadingnewtext = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        },
        goToEdit: function(text) {
            this.$store.commit('newEditText', text)
            this.$router.push({ name: 'edittext'})
        },
        loadTexts: function() {
            this.isloading = true

            // GET '/clients/:clientid/texts'
            var url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_CLIENT
            url += '/' + Vue.getSessionData().clientid
            url += '/texts'

            axios.get(url)
            .then(response => {
                var status = response.data.status
                var data = response.data.data

                if (status.ErrorCode != 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.textlist = data.textlist
                    this.isloading = false
                }
            })
            .catch(err => {
                Vue.$toast.error(err)
            })
        }
    },
    components: {
        Modal,
        FloatingDropdown
    }
}
</script>

<style scoped>
</style>